import { List, ListItem } from 'framework7-react';
import { FC, useState } from 'react';

import * as dayjs from 'dayjs';

import {
  useCurrentUserEventRsvp,
  useEventCritiqueDataQuery,
  useEventInterestDataQuery,
  useEventQuery,
} from '@queries/EventQueries';
import { FeedPost, useEventCommentsQuery } from '@queries/PostQueries';
import { useCurrentUser } from '@queries/UserQueries';

import {
  CritiqueDocumentMutationPayload,
  useCritiqueDocumentMutation,
} from '@mutations/CritiqueMutations';
import { RsvpToEventPayload, useRsvpToEventMutation } from '@mutations/EventMutations';
import { useCreateEventComment } from '@mutations/PostMutations';

import { useTipEntertainer } from '@hooks/EntertainerHooks/useTipEntertainer';

import { useLocation, useNavigate, useParams } from '@router/index';

import { mapStringToParagraphs } from '@utilities/mapStringToParagraphs';
import { openExternalUrl } from '@utilities/openExternalUrl';

import Button from '@components/buttons/Button';
import IconButton from '@components/buttons/IconButton';
import { EventActions, EventHeader, EventImage, PromoterHeader } from '@components/cards/EventCard';
import { PostCard } from '@components/cards/PostCard/PostCard';
import { Textarea } from '@components/form/Textarea';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import { CritiqueSection } from '@components/layout/DetailsChartSection/CritiqueSection';
import { EventInterestSection } from '@components/layout/DetailsChartSection/EventInterestSection';
import { EmptyState } from '@components/layout/EmptyState/EmptyState';
import { Screen } from '@components/layout/Screen/Screen';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';
import { PrimaryCritiqueCountChips } from '@components/overlays/CritiqueOverlay/PrimaryCritiqueCountChips';
import { ReportOverlay } from '@components/overlays/ReportOverlay/ReportOverlay';

import { VenmoLogo } from '../assets/VenmoLogo';
import { EventCleanupPopup } from './EventCleanupScreen';
import './EventScreen.scss';
import { useAuthGate } from './LogInScreen/AuthGateContext';

const EventCommentSection: FC<{
  eventId: string;
  commentFieldIsVisible: boolean;
  setCommentFieldIsVisible: (updatedValue: boolean) => void;
}> = ({ eventId, commentFieldIsVisible, setCommentFieldIsVisible }) => {
  const [commentText, setCommentText] = useState<string>('');

  const { createAuthGateHandler } = useAuthGate();
  const { eventComments = [], eventCommentsAreReady } = useEventCommentsQuery(eventId);
  const { isSubmitting, createEventCommentAsync } = useCreateEventComment(eventId);

  const handleSubmitComment = async () => {
    if (!commentText || isSubmitting) {
      return;
    }

    createAuthGateHandler(async () => {
      await createEventCommentAsync({ text: commentText });

      setCommentText('');
    });
  };

  return (
    <>
      <div id="event-comments" />
      <TitleToolbar
        size="md"
        text="Comments"
        action={
          <Button disabled={false} variant="flat" onClick={() => setCommentFieldIsVisible(true)}>
            Add Comment
          </Button>
        }
      />
      {commentFieldIsVisible && (
        <Textarea
          onChange={setCommentText}
          submitButton={{
            text: 'Comment',
            disabled: isSubmitting,
            onClick: handleSubmitComment,
          }}
        />
      )}

      {eventCommentsAreReady && (
        <>
          {eventComments.length ? (
            <div className="comments">
              {eventComments.map((comment) => (
                <PostCard key={comment._id} post={comment} showDetails={false} />
              ))}
            </div>
          ) : (
            <EmptyState
              className="comments-empty-state"
              title="No Comments Yet"
              text="Be the first to comment or ask a question about this event."
              button={{
                text: 'Add Comment',
                onClick: () => setCommentFieldIsVisible(true),
              }}
            />
          )}
        </>
      )}
    </>
  );
};

interface EventScreenProps {
  back?: string;
}

const TipEntertainerButton: FC<{ entertainer: any }> = ({ entertainer }) => {
  const { handleVenmoEntertainer, tipEntertainerPopup } = useTipEntertainer();
  const handleTip = () => {
    handleVenmoEntertainer({
      entertainerId: entertainer._id,
      venmo: entertainer?.contact?.venmo,
    });
  };

  return (
    <>
      <Button
        size="sm"
        roundness="rounded"
        style={{
          borderRadius: 4,
        }}
        startIcon={
          <div style={{ width: 14, height: 14 }}>
            <VenmoLogo />
          </div>
        }
        onClick={handleTip}
      >
        Tip
      </Button>
      {tipEntertainerPopup}
    </>
  );
};

const useEventRsvpActions = (eventId: string) => {
  const { rsvpToEventAsync, isSubmitting } = useRsvpToEventMutation();
  const { refetchEventInterestData } = useEventInterestDataQuery(eventId);

  const handleMarkAttending = async () => {
    await rsvpToEventAsync({
      eventId,
      rsvp: 'yes',
    });
    refetchEventInterestData();
  };

  const handleMarkNotAttending = async () => {
    await rsvpToEventAsync({
      eventId,
      rsvp: 'no',
    });
    refetchEventInterestData();
  };

  const handleMarkInterested = async () => {
    await rsvpToEventAsync({
      eventId,
      eventInterest: 'interested',
    });
    refetchEventInterestData();
  };

  const handleMarkNotInterested = async () => {
    await rsvpToEventAsync({
      eventId,
      eventInterest: 'notInterested',
    });
    refetchEventInterestData();
  };

  return {
    handleMarkAttending,
    handleMarkNotAttending,
    handleMarkInterested,
    handleMarkNotInterested,
    isSubmitting,
  };
};

export const EventScreen: FC<EventScreenProps> = ({ back = '/home' }) => {
  const { eventId } = useParams<{ eventId: string }>();
  const { eventIsReady, event } = useEventQuery(eventId);
  const { rsvp } = useCurrentUserEventRsvp(eventId);
  const { eventCritiqueData, refetchEventCritiqueData } = useEventCritiqueDataQuery(eventId);
  const { eventInterestData, refetchEventInterestData } = useEventInterestDataQuery(eventId);
  const { currentUser } = useCurrentUser();
  const [commentFieldIsVisible, setCommentFieldIsVisible] = useState<boolean>(false);
  const [reportOverlayIsVisible, setReportOverlayIsVisible] = useState(false);
  const navigate = useNavigate();

  const { rsvpToEventAsync, isSubmitting } = useRsvpToEventMutation();
  const { critiqueDocumentAsync } = useCritiqueDocumentMutation();

  const handleScrollToComments = () => {
    document.getElementById('event-comments')?.scrollIntoView({ behavior: 'smooth' });
    setCommentFieldIsVisible(true);
  };

  const handleUpdateCritique = async (payload: CritiqueDocumentMutationPayload) => {
    await critiqueDocumentAsync({
      ...payload,
      documentType: 'events',
      documentId: eventId,
    });
    refetchEventCritiqueData();
  };

  const handleUpdateEventInterest = async (payload: RsvpToEventPayload) => {
    await rsvpToEventAsync({
      ...payload,
      eventId,
    });
    refetchEventInterestData();
  };

  const {
    handleMarkInterested,
    handleMarkNotInterested,
    handleMarkAttending,
    handleMarkNotAttending,
  } = useEventRsvpActions(eventId);

  const attendingToolbarButtons = (
    <>
      <div className="w-50">
        <Button
          disabled={isSubmitting}
          noWrap
          roundness="rounded"
          color="neutral"
          variant={rsvp?.rsvp === 'no' ? 'default' : 'outlined'}
          startIcon={
            <FontAwesomeIcon faStyle="far" name={rsvp?.rsvp === 'no' ? 'times-circle' : 'circle'} />
          }
          onClick={handleMarkNotAttending}
        >
          Not attending
        </Button>
      </div>
      <div className="w-50">
        <Button
          noWrap
          roundness="rounded"
          disabled={isSubmitting}
          variant={rsvp?.rsvp === 'yes' ? 'default' : 'outlined'}
          startIcon={
            <FontAwesomeIcon
              faStyle="far"
              name={rsvp?.rsvp === 'yes' ? 'check-circle' : 'circle'}
            />
          }
          onClick={handleMarkAttending}
        >
          Attending
        </Button>
      </div>
    </>
  );

  const interestToolbarButtons = (
    <>
      <div className="w-50">
        <Button
          disabled={isSubmitting}
          noWrap
          roundness="rounded"
          color="neutral"
          variant={rsvp?.eventInterest === 'notInterested' ? 'default' : 'outlined'}
          startIcon={
            <FontAwesomeIcon
              faStyle="far"
              name={rsvp?.eventInterest === 'notInterested' ? 'times-circle' : 'circle'}
            />
          }
          onClick={handleMarkNotInterested}
        >
          Not interested
        </Button>
      </div>
      <div className="w-50">
        <Button
          noWrap
          roundness="rounded"
          disabled={isSubmitting}
          variant={rsvp?.eventInterest === 'interested' ? 'default' : 'outlined'}
          startIcon={
            <FontAwesomeIcon
              faStyle="far"
              name={rsvp?.eventInterest === 'interested' ? 'check-circle' : 'circle'}
            />
          }
          onClick={handleMarkInterested}
        >
          Interested
        </Button>
      </div>
    </>
  );

  const isPastEvent =
    event && dayjs().isAfter(`${event?.startDate} ${event?.startTime ?? '00:00'}`);

  return (
    <Screen
      className="event-screen"
      name="Event Screen"
      disableLocationPermissionGate
      headerProps={{
        back: { to: back },
        title: 'Event',
        right: (
          <IconButton
            disabled={false}
            variant="flat"
            color="danger"
            onClick={() => setReportOverlayIsVisible(true)}
          >
            <FontAwesomeIcon faStyle="far" name="flag" />
          </IconButton>
        ),
      }}
      bottomToolbar={
        !isPastEvent && (
          <Screen.BottomToolbar>
            <div className="d-flex gap-2 w-100">{interestToolbarButtons}</div>
          </Screen.BottomToolbar>
        )
      }
    >
      <Screen.Content>
        {eventIsReady && event && (
          <>
            <PromoterHeader event={event} />
            <EventImage event={event} />
            <EventHeader event={event} />
            {currentUser?.isSuperAdmin && (
              <EventActions
                event={event}
                onCommentClick={handleScrollToComments}
                showCleanup={currentUser?.isSuperAdmin}
              />
            )}
            <TitleToolbar size="md" text="About This Event" />
            <div className="event-description">
              {mapStringToParagraphs(event.excerpt ?? event.description)}
            </div>

            {!!event.entertainers?.length && (
              <>
                <TitleToolbar size="md" text="Entertainers 💃🏽" />
                <List className="mt-0 mb-2" style={{ marginLeft: ' -1rem', marginRight: '-1rem' }}>
                  {event.entertainers.map((entertainer) => (
                    <ListItem key={entertainer._id}>
                      <div
                        slot="title"
                        onClick={() => {
                          if (entertainer.bookingHandle) {
                            navigate(`/noauth/entertainer/${entertainer.bookingHandle}`);
                          }
                        }}
                      >
                        {entertainer.title}
                      </div>
                      <div slot="media">
                        {entertainer.contact?.instagram && (
                          <IconButton
                            variant="flat"
                            color="neutral"
                            style={{
                              borderRadius: 4,
                              fontSize: 18,
                              height: 22,
                              width: 22,
                            }}
                            onClick={() => {
                              openExternalUrl(
                                `https://instagram.com/${entertainer.contact?.instagram}`,
                              );
                            }}
                          >
                            <FontAwesomeIcon faStyle="fab" name="instagram" />
                          </IconButton>
                        )}
                      </div>
                      <div slot="after">
                        <div className="d-flex gap-2">
                          {entertainer.contact?.venmo && (
                            <TipEntertainerButton entertainer={entertainer} />
                          )}
                          {/* <PrimaryCritiqueCountChips
                          hideCounts
                          disableOverlay
                          controlSelectedRating
                          defaultValue={entertainer.primaryRating}
                          counts={{
                            like: 0,
                            dislike: 0,
                            neutral: 0,
                          }}
                          onSubmit={async () => {}}
                        /> */}
                        </div>
                      </div>
                    </ListItem>
                  ))}
                </List>
              </>
            )}

            {isPastEvent && eventCritiqueData && (
              <>
                <TitleToolbar size="md" text="Critiques 💅🏽" />
                <CritiqueSection critiqueData={eventCritiqueData} onSubmit={handleUpdateCritique} />
              </>
            )}

            {eventInterestData && (
              <>
                <TitleToolbar size="md" text="Hot Takes 🔥" />
                <EventInterestSection
                  showShareButton={!isPastEvent}
                  currentUserRsvp={rsvp}
                  interestData={eventInterestData}
                  onSubmit={handleUpdateEventInterest}
                />
              </>
            )}

            <EventCommentSection
              eventId={eventId}
              commentFieldIsVisible={commentFieldIsVisible}
              setCommentFieldIsVisible={setCommentFieldIsVisible}
            />
          </>
        )}
      </Screen.Content>
      <ReportOverlay
        isVisible={reportOverlayIsVisible}
        title="Report Event"
        content={<p>Are you sure you want to report this event?</p>}
        data={{
          collectionName: 'event',
          objectId: eventId,
        }}
        onClose={() => setReportOverlayIsVisible(false)}
      />
    </Screen>
  );
};
