import { useMutation } from '@tanstack/react-query';
import { FC, ReactNode, useState } from 'react';

import API from '@api/API';

import Button from '@components/buttons/Button';
import { Textarea } from '@components/form/Textarea';
import { Popup } from '@components/layout/Popup/Popup';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

interface ReportDocumentPayload {
  collectionName: 'venue' | 'event' | 'post' | 'promoter' | 'support';
  objectId: string;
  comment: string;
}

const reportDocument = (data: ReportDocumentPayload) => {
  return API.post('/v1/user/feedback', data);
};

const useReportMutation = () => {
  const { isPending, mutateAsync } = useMutation({
    mutationFn: reportDocument,
  });

  return {
    isSubmitting: isPending,
    reportAsync: mutateAsync,
  };
};

interface ReportOverlayProps {
  isVisible: boolean;
  title?: string;
  content: ReactNode;
  submitButtonText?: string;
  data: Omit<ReportDocumentPayload, 'comment'>;
  onClose: () => void;
}

export const ReportOverlay: FC<ReportOverlayProps> = ({
  isVisible,
  title = 'Report',
  content,
  submitButtonText = 'Report',
  data,
  onClose,
}) => {
  const [comment, setComment] = useState('');
  const { isSubmitting, reportAsync } = useReportMutation();

  const handleReport = () => {
    reportAsync({
      ...data,
      comment,
    });
  };

  return (
    <Popup isVisible={isVisible} onClose={onClose}>
      <Popup.Content>
        <TitleToolbar
          text={title}
          size="md"
          action={
            <Button variant="outlined" color="neutral" size="sm" onClick={onClose}>
              Cancel
            </Button>
          }
        />
        {content}
        <Textarea
          placeholder="Please provide details"
          onChange={setComment}
          submitButton={{
            text: submitButtonText,
            disabled: !comment || isSubmitting,
            onClick: async () => {
              handleReport();
              onClose();
            },
          }}
        />
      </Popup.Content>
    </Popup>
  );
};
