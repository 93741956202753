import { FC, useMemo, useState } from 'react';
import { PieChart } from 'react-minimal-pie-chart';

import { useAppContext } from '@contexts/AppContext';
import dayjs from 'dayjs';
import { sum } from 'lodash';

import { EntertainerRank, useTopEntertainersQuery } from '@queries/EntertainerQueries';

import {
  CritiqueDocumentMutationPayload,
  useCritiqueDocumentMutation,
  useTopEntertainersOptimisticUpdate,
} from '@mutations/CritiqueMutations';

import { useAnalytics } from '@hooks/useAnalytics';
import { useCurrentUserCity } from '@hooks/useCurrentUserLocation';

import { useNavigate } from '@router/index';

import Button from '@components/buttons/Button';
import { PostCardHeaderWrapper } from '@components/cards/PostCard/PostCard';
import Card from '@components/layout/Card/Card';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';
import { BASIC_RATING_COLOR_RECORD } from '@components/overlays/CritiqueOverlay/CritiqueOverlayConstants';
import { PrimaryCritiqueCountChips } from '@components/overlays/CritiqueOverlay/PrimaryCritiqueCountChips';

import { EntertainerCritiquePopup } from '@screens/entertainer/EntertainerRankingsScreen/EntertainerCritiquePopup';

export const EntertainerCritiquePopupButton: FC<{ entertainerId: string }> = ({
  entertainerId: originalEntertainerId,
}) => {
  const { track } = useAnalytics();
  const navigate = useNavigate();
  const type = 'dragPerformer'; // entertainer type
  const { userIsLoggedIn } = useAppContext();
  const { currentCity } = useCurrentUserCity();
  const currentCityId = currentCity?._id;
  const [isVisible, setIsVisible] = useState(false);
  const {
    topEntertainersAreReady,
    topEntertainers = [],
    totalEntertainers,
    refetchTopEntertainers,
  } = useTopEntertainersQuery(currentCityId, type, userIsLoggedIn);
  const { handleTopEntertainersOptimisticUpdate } = useTopEntertainersOptimisticUpdate(
    currentCityId,
    type,
    true,
  );

  const entertainer = useMemo(() => {
    return topEntertainers?.find((entertainer) => entertainer._id === originalEntertainerId);
  }, [originalEntertainerId, topEntertainers]);

  const { critiqueDocumentAsync } = useCritiqueDocumentMutation();
  const handleClickRating =
    (entertainerId: string) => async (payload: CritiqueDocumentMutationPayload) => {
      track('Click rate entertainer', {
        ...payload,
        entertainerId,
      });
      handleTopEntertainersOptimisticUpdate(entertainerId, {
        primaryCritique: payload.primaryCritique,
      });
      await critiqueDocumentAsync({
        ...payload,
        documentType: 'entertainers',
        documentId: entertainerId,
      });
    };

  const handleUpdateStrengths =
    (entertainerId: string) => async (payload: CritiqueDocumentMutationPayload) => {
      track('Update strengths', {
        ...payload,
        entertainerId,
      });
      handleTopEntertainersOptimisticUpdate(entertainerId, {
        like: payload.like,
      });
      await critiqueDocumentAsync({
        ...payload,
        documentType: 'entertainers',
        documentId: entertainerId,
      });
    };

  const handleUpdateImprovements =
    (entertainerId: string) => async (payload: CritiqueDocumentMutationPayload) => {
      track('Update improvements', {
        ...payload,
        entertainerId,
      });
      handleTopEntertainersOptimisticUpdate(entertainerId, {
        neutral: payload.neutral,
      });
      await critiqueDocumentAsync({
        ...payload,
        documentType: 'entertainers',
        documentId: entertainerId,
      });
    };

  const handleNavigateToEntertainer = (entertainer: EntertainerRank) => {
    navigate(`/noauth/entertainer/${entertainer.bookingHandle}`);
  };

  return (
    <>
      <Button
        className="py-2 px-3"
        size="sm"
        roundness="rounded"
        variant="smooth"
        color="neutral"
        onClick={() => setIsVisible(true)}
      >
        Details
      </Button>
      <EntertainerCritiquePopup
        selectedEntertainer={isVisible ? entertainer : undefined}
        onClickRating={handleClickRating}
        onUpdateStrengths={handleUpdateStrengths}
        onUpdateImprovements={handleUpdateImprovements}
        onClickViewProfile={handleNavigateToEntertainer}
        onClose={() => setIsVisible(false)}
      />
    </>
  );
};

export const RatingActivityCard = ({ post }: { post: any }) => {
  const { track } = useAnalytics();
  const [userPrimaryRating, setUserPrimaryRating] = useState<string>();

  const primaryRatings = useMemo<Record<string, number>>(() => {
    return Object.fromEntries(
      Object.entries(post.basicRatings).map(([key, value]: [string, number]) => [
        key,
        key === userPrimaryRating ? value + 1 : value,
      ]),
    );
  }, [post.basicRatings, userPrimaryRating]);

  const pieChartData: { key: string; value: number; color: string }[] = useMemo(() => {
    const total = sum(Object.values(primaryRatings));

    return Object.entries(primaryRatings)
      .filter(([, value]) => value)
      .map(([key, value]: [string, number]) => ({
        key,
        value: Math.round((value / total) * 100),
        color: BASIC_RATING_COLOR_RECORD[key],
      }));
  }, [primaryRatings]);

  const { critiqueDocumentAsync } = useCritiqueDocumentMutation();
  const handleSelectPrimaryRating = async (payload: CritiqueDocumentMutationPayload) => {
    track('Rating Activity Rate Click', {
      documentId: post.documentId,
      title: post.entertainer.title,
      primaryCritique: payload.primaryCritique,
    });

    setUserPrimaryRating(payload.primaryCritique);

    await critiqueDocumentAsync({
      primaryCritique: payload.primaryCritique,
      documentType: 'entertainers',
      documentId: post.documentId,
    });
  };

  return (
    <Card>
      <PostCardHeaderWrapper
        wrapperTitle="Latest Ratings"
        title={post.entertainer.title ?? 'what'}
        createdAt={dayjs(post.createdAt).toISOString()}
        action={<EntertainerCritiquePopupButton entertainerId={post.documentId} />}
      />
      <Card.Body>
        <div className="d-flex gap-3">
          <div style={{ width: '33.3333%' }}>
            <PieChart
              rounded
              lineWidth={25}
              radius={50}
              data={pieChartData}
              paddingAngle={25}
              // label={({ dataEntry }) => `${dataEntry.value}%`}
              labelStyle={(index) => ({
                fill: pieChartData[index].color,
                fontSize: '12px',
                fontWeight: 'bold',
                fontFamily: 'Rubik',
                letterSpacing: 1,
              })}
            />
          </div>
          <div style={{ width: '66.6666%' }}>
            <div
              className="d-flex flex-column justify-content-center align-items-center h-100"
              style={{
                background: 'rgba(0,0,0,.2)',
                borderRadius: 8,
              }}
            >
              <div className="text-align-center mb-1">
                <strong>What's your take?</strong>
              </div>
              <div className="text-align-center">
                <div className="d-inline-flex">
                  <PrimaryCritiqueCountChips
                    hideCounts
                    disableOverlay
                    controlSelectedRating
                    counts={{
                      like: 0,
                      dislike: 0,
                      neutral: 0,
                    }}
                    onSubmit={handleSelectPrimaryRating}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
