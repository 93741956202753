import { useMemo } from 'react';

import { Capacitor } from '@capacitor/core';

export const useDevice = () => {
  const isNativePlatform = Capacitor.isNativePlatform;

  const { isAndriod, isIOS, isWeb } = useMemo(() => {
    try {
      const platform = Capacitor.getPlatform();

      return {
        isAndriod: platform === 'android',
        isIOS: platform === 'ios',
        isWeb: platform === 'web',
      };
    } catch (error) {
      return {
        isAndriod: false,
        isIOS: false,
        isWeb: true,
      };
    }
  }, []);

  return {
    isNativePlatform,
    isAndriod,
    isIOS,
    isWeb,
  };
};
