import { useMemo } from 'react';

import dayjs from 'dayjs';

import { useCurrentEntertainerQuery } from '@queries/EntertainerQueries';
import { useCurrentPromoterQuery } from '@queries/PromoterQueries';
import { useCurrentUser, useUserConversationsQuery } from '@queries/UserQueries';

import { useNavigate } from '@router/index';

import { Screen } from '@components/layout/Screen';
import { Skeleton } from '@components/layout/Skeleton/Skeleton';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import {
  NoticationsList,
  NotificationListItem,
} from '@screens/NotificationsScreen/NotificationsScreen';

export const ConversationsScreen = ({
  profileType,
  back,
}: {
  profileType: 'entertainer' | 'promoter' | 'user';
  back: string;
}) => {
  const { userConversationsAreReady, userConversations, refetchUserConversations } =
    useUserConversationsQuery();
  const { currentUser } = useCurrentUser();
  const { entertainer } = useCurrentEntertainerQuery();
  const { promoter } = useCurrentPromoterQuery();
  const navigate = useNavigate();

  const currentProfileId = useMemo(() => {
    if (profileType === 'entertainer') {
      return entertainer?._id;
    }

    if (profileType === 'promoter') {
      return promoter?._id;
    }

    return currentUser?._id;
  }, [profileType, currentUser, entertainer, promoter]);

  const notificationsList = useMemo<NotificationListItem[]>(() => {
    if (!profileType) {
      return [];
    }

    const MAX_CHARS = 30;

    return (
      userConversations?.[profileType]?.map((conversation) => {
        const timeAgo = dayjs(conversation.lastMessageAt).fromNow();
        const otherProfileType = profileType === 'entertainer' ? 'promoter' : 'entertainer';

        return {
          isUnread: conversation.isUnread,
          avatarLetter: conversation.title[0] ?? 'G',
          title: conversation.title,
          subTitle:
            conversation.text.length > MAX_CHARS
              ? `${conversation.text.substring(0, MAX_CHARS).trim()}...`
              : conversation.text,
          endIcon: (
            <span slot="after" style={{ fontSize: 12 }}>
              {timeAgo}
            </span>
          ),
          onClick: () => {
            navigate(
              `/chat/${profileType}/${currentProfileId}/${otherProfileType}/${conversation.entertainerId ?? conversation.promoterId ?? conversation.userId}`,
            );
          },
        };
      }) ?? []
    );
  }, [profileType, userConversations]);

  const handleReenter = () => {
    refetchUserConversations();
  };

  return (
    <Screen
      name="Conversations Screen"
      hideToolbar
      headerProps={{
        title: 'Conversations',
        back: back ? { to: back } : undefined,
      }}
      onReenter={handleReenter}
    >
      <Screen.Content>
        {userConversationsAreReady ? (
          <NoticationsList listItems={notificationsList} />
        ) : (
          <div className="grid gap-2">
            <Skeleton height={60} />
            <Skeleton height={60} />
            <Skeleton height={60} />
            <Skeleton height={60} />
            <Skeleton height={60} />
            <Skeleton height={60} />
            <Skeleton height={60} />
            <Skeleton height={60} />
            <Skeleton height={60} />
            <Skeleton height={60} />
            <Skeleton height={60} />
          </div>
        )}
      </Screen.Content>
    </Screen>
  );
};
