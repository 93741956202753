import { InputHTMLAttributes, forwardRef, useEffect, useRef } from 'react';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  focusOnMount?: boolean;
  variant?: 'outlined' | 'default';
  color?: 'default' | 'neutral';
  wrapperClassName?: string;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    { type = 'text', focusOnMount, variant = 'default', wrapperClassName = '', ...props },
    forwardedRef,
  ) => {
    const internalRef = useRef<HTMLInputElement>();
    const ref = forwardedRef ?? internalRef;

    useEffect(() => {
      if (focusOnMount) {
        if (internalRef.current) {
          internalRef.current.focus();
        } else {
          // Check if forwardedRef is a function or an object
          if (typeof forwardedRef === 'function') {
            // If it's a function, call it with the current value
            forwardedRef(internalRef.current);
          } else if (forwardedRef && 'current' in forwardedRef) {
            // If it's an object with a 'current' property, access it directly
            forwardedRef.current?.focus();
          }
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <div className={`input input-variant-${variant} ${wrapperClassName}`}>
        <input ref={ref} type={type} {...props} />
      </div>
    );
  },
);
