import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';

import { event } from 'cypress/types/jquery';

import API from '@api/API';

import { useAppContext } from '../contexts/AppContext';
import { BasicRating, CritiqueData, UserCritique } from './CritiqueQueries';
import { FeedEvent } from './EventQueries';
import { FeedPost } from './PostQueries';
import { PublicBookingOpportunity } from './PromoterQueries';

export const TYPES_RECORD = {
  dragPerformer: 'Drag Performer',
  dj: 'DJ',
  gogo: 'Go-Go',
  comedian: 'Comedian',
  other: 'Other',
} as const;

export type EntertainerType = keyof typeof TYPES_RECORD;

export interface Entertainer {
  _id: string;
  avatar: string;
  title: string;
  tagline: string;
  description: string;
  type: EntertainerType;
  cityIds: string[];
  cities: { _id: string; name: string }[];
  details: any;
  tags: {
    like: string[];
    neutral: string[];
    dislike: string[];
  };
  bookingHandle?: string;
  smsKeyword?: string;
  contact?: {
    email?: string;
    instagram?: string;
    facebook?: string;
    venmo?: string;
  };
}
export interface EntertainerBookingOpportunity {
  primaryImage?: string;
  _id: string;
  title: string;
  details: string;
  startDate: string;
  startTime: string;
  city: { name: string };
  venue: { title: string };
  promoter: { _id: string; title: string };
  status: PublicBookingOpportunity['entertainers'][0]['status'] | 'new';
  updates: PublicBookingOpportunity['updates'];
}

export interface EntertainerBookingRequest {
  _id: string;
  status: string;
  requestedBy: string;
  createdAt: string;
  city: {
    _id: string;
    name: string;
  };
  details: {
    startDate: string;
    startTime: string;
    venue: { title: string };
  };
  contact: {
    name: string;
    phone: string;
    email: string;
  };
  performance: {
    duration: string;
    type: string;
    specialRequests: string;
    additionalQuestions?: string;
  };
  technical: {
    soundSystem: string;
    dressingRoom: string;
    lightingSetup: string;
  };
}

export interface EntertainerCompetition {
  _id: string;
  title: string;
  subtitle?: string;
  startDate: string;
  endDate: string;
  instructions?: string;
}

export const useCurrentEntertainerQuery = () => {
  const { userIsLoggedIn } = useAppContext();

  const {
    isFetched: entertainerIsReady,
    data,
    refetch: refetchEntertainer,
  } = useQuery({
    queryKey: useCurrentEntertainerQuery.queryKey(),
    queryFn: () => useCurrentEntertainerQuery.queryFn(),
    enabled: userIsLoggedIn,
  });

  return {
    entertainerIsReady,
    entertainer: data?.entertainer,
    bookingOpportunities: data?.bookingOpportunities,
    bookingRequests: data?.bookingRequests,
    upcomingEvents: data?.upcomingEvents,
    competitions: data?.competitions,
    eventRequests: data?.eventRequests,
    refetchEntertainer,
  };
};
useCurrentEntertainerQuery.queryKey = () => ['user', 'entertainer'];
useCurrentEntertainerQuery.queryFn = () =>
  API.get<{
    entertainer: Entertainer;
    bookingOpportunities: EntertainerBookingOpportunity[];
    bookingRequests: EntertainerBookingRequest[];
    upcomingEvents: (FeedEvent & { canUserEdit: boolean })[];
    competitions: EntertainerCompetition[];
    eventRequests: FeedEvent[];
  }>(`/v1/entertainer/me`);

export const fetchCurrentEntertainer = async (queryClient: QueryClient) => {
  await queryClient.invalidateQueries({ queryKey: useCurrentEntertainerQuery.queryKey() });

  return queryClient.fetchQuery({
    queryKey: useCurrentEntertainerQuery.queryKey(),
    queryFn: () => useCurrentEntertainerQuery.queryFn(),
  });
};

export const useFetchCurrentEntertainer = () => {
  const queryClient = useQueryClient();

  return () => fetchCurrentEntertainer(queryClient);
};

export interface EntertainerLink {
  type: string;
  url: string;
  text: string;
}
export interface UnauthorizedPublicEntertainer {
  _id: string;
  isDemoEnabled?: {
    primaryCommentDisplayName: string;
    primaryCommentText: string;
  };
  isClaimed: boolean;
  avatar?: string;
  title: string;
  tagline?: string;
  description?: string;
  tags?: {
    like: string[];
    neutral: string[];
    dislike: string[];
  };
  type?: string;
  cities: { _id: string; name: string; rank: number; total: number }[];
  contact?: {
    instagram?: string;
    venmo?: string;
    tiktok?: string;
    email?: string;
    website?: string;
    links?: EntertainerLink[];
  };
  details: {
    categories: string[];
  };
}

export const useEntertainerByBookingHandleQuery = (bookingHandle: string) => {
  const { isFetched, data } = useQuery({
    queryKey: ['entertainer', 'bookingHandle', bookingHandle],
    queryFn: () =>
      API.get<{
        entertainer: UnauthorizedPublicEntertainer;
        upcomingEvents: FeedEvent[];
        activeCompetitions: { _id: string; title: string }[];
      }>(`/v1/entertainers/bookingHandle/${bookingHandle}`),
    enabled: !!bookingHandle,
  });

  return {
    entertainerIsReady: isFetched,
    entertainer: data?.entertainer,
    upcomingEntertainerEvents: data?.upcomingEvents,
    activeEntertainerCompetitions: data?.activeCompetitions,
  };
};

export const useEntertainerReviewsQuery = (entertainerId?: string) => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: useEntertainerReviewsQuery.queryKey(entertainerId),
    queryFn: () => useEntertainerReviewsQuery.queryFn(entertainerId),
    enabled: !!entertainerId,
  });

  return {
    reviewsAreReady: isFetched,
    userPrimaryRating: data?.userPrimaryRating,
    reviews: data?.reviews,
    refetchReviews: refetch,
  };
};
useEntertainerReviewsQuery.queryKey = (entertainerId: string) => [
  'entertainer-reviews',
  entertainerId,
];
useEntertainerReviewsQuery.queryFn = (entertainerId: string) =>
  API.get<{
    userPrimaryRating?: BasicRating;
    reviews: FeedPost[];
  }>(`/v1/entertainers/${entertainerId}/reviews`);

export interface EntertainerRank {
  _id: string;
  title: string;
  rank: number;
  bookingHandle: string;
  tags: {
    like: string[];
    neutral: string[];
    dislike: string[];
  };
  primaryRating: string;
  like: { label: string }[];
  neutral: { label: string }[];
  dislike: { label: string }[];
}
export const useTopEntertainersQuery = (cityId: string, type: string, userIsLoggedIn: boolean) => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: useTopEntertainersQuery.queryKey(cityId, type, userIsLoggedIn),
    queryFn: () => useTopEntertainersQuery.queryFn(cityId, type),
    enabled: !!cityId && !!type,
  });

  return {
    topEntertainersAreReady: isFetched,
    totalEntertainers: data?.total,
    topEntertainers: data?.entertainers,
    refetchTopEntertainers: refetch,
  };
};
useTopEntertainersQuery.queryKey = (cityId: string, type: string, userIsLoggedIn: boolean) => [
  'top-entertainers',
  type,
  cityId,
  userIsLoggedIn,
];
useTopEntertainersQuery.queryFn = (cityId: string, type: string) =>
  API.get<{
    total: number;
    entertainers: EntertainerRank[];
  }>(`/v1/entertainers/top?cityId=${cityId}&type=${type}`);

export const useEntertainerCritiqueDataQuery = (entertainerId: string, isEnabled = true) => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: useEntertainerCritiqueDataQuery.queryKey(entertainerId),
    queryFn: () => useEntertainerCritiqueDataQuery.queryFn(entertainerId),
    enabled: isEnabled && !!entertainerId,
    refetchOnMount: 'always',
    refetchOnReconnect: 'always',
    refetchOnWindowFocus: 'always',
  });

  return {
    entertainerCritiqueDataIsReady: isFetched,
    entertainerCritiqueData: data?.critiqueData,
    refetchEntertainerCritiqueData: refetch,
  };
};
useEntertainerCritiqueDataQuery.queryKey = (entertainerId) => [
  'entertainer-critiques',
  entertainerId,
];
useEntertainerCritiqueDataQuery.queryFn = (entertainerId) =>
  API.get<{ critiqueData: CritiqueData }>(`/v1/entertainers/${entertainerId}/critiques`);

export const useCurrentUserEntertainerCritiqueQuery = (entertainerId: string, isEnabled = true) => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: useCurrentUserEntertainerCritiqueQuery.queryKey(entertainerId),
    queryFn: () => useCurrentUserEntertainerCritiqueQuery.queryFn(entertainerId),
    enabled: isEnabled && !!entertainerId,
  });

  return {
    userEntertainerCritiqueIsReady: isFetched,
    userEntertainerCritique: data?.userCritique,
    refetchUserEntertainerCritique: refetch,
  };
};
useCurrentUserEntertainerCritiqueQuery.queryKey = (entertainerId) => [
  'user-critiques',
  'entertainers',
  entertainerId,
];
useCurrentUserEntertainerCritiqueQuery.queryFn = (entertainerId) =>
  API.get<{ userCritique: UserCritique }>(`/v1/user/critiques/entertainers/${entertainerId}`);
