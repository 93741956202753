import {
  NavLeft,
  NavRight,
  NavTitle,
  Navbar,
  Page,
  PageContent,
  Subnavbar,
  Toolbar,
  f7ready,
} from 'framework7-react';
import {
  PropsWithChildren,
  ReactElement,
  ReactNode,
  forwardRef,
  memo,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';

import { useAppContext } from '@contexts/AppContext';
import { isEmpty } from 'lodash';

import { useAnalytics } from '@hooks/useAnalytics';

import { useManagedHistory } from '@router/index';

import { GagglLogo } from '../../../assets/GagglLogo';
import './Screen.scss';

interface ScreenHeaderProps {
  back?: {
    to: string;
    label?: string;
  };
  left?: ReactNode;
  title?: string | ReactElement;
  right?: ReactNode;
  subnavbar?: ReactElement;
  pageUrl?: string;
}

const ScreenHeader = memo((props: ScreenHeaderProps) => {
  if (!isEmpty(props)) {
    const { left, title, right, subnavbar } = props;
    const { backlinkUrl } = useManagedHistory();

    const back =
      backlinkUrl && !left
        ? {
            to: backlinkUrl,
            label: undefined,
          }
        : undefined;

    return (
      <Navbar>
        {back && !left && (
          <NavLeft
            backLink={back?.label ?? true}
            backLinkUrl={back?.to}
            backLinkForce={!!back?.to}
          />
        )}
        {!back && left && <NavLeft>{left}</NavLeft>}
        {title && <NavTitle>{title}</NavTitle>}
        {right && <NavRight>{right}</NavRight>}
        {subnavbar && <Subnavbar>{subnavbar}</Subnavbar>}
      </Navbar>
    );
  }

  return <Navbar hidden />;

  // return (
  //   <div className="screen-header">
  //     <div className="screen-header-inner">
  //       <div className="screen-header-left">
  //         {back && (
  //           <Link to={back.to}>
  //             <FontAwesomeIcon name="chevron-left" />
  //             {back.label && <span>{back.label}</span>}
  //           </Link>
  //         )}
  //       </div>
  //       <div className="screen-header-title">{title && <h1>{title}</h1>}</div>
  //       <div className="screen-header-right">{right}</div>
  //     </div>
  //   </div>
  // );
});

export const Screen = ({
  className = '',
  children,
  name: screenName,
  disableLocationPermissionGate = false,
  metaData = {},
  headerProps,
  bottomToolbar,
  hideToolbar,
  onReenter,
  onEnter,
}: {
  children: ReactNode;
  name: string;
  className?: string;
  metaData?: {
    title?: string;
    description?: string;
    image?: string;
  };
  headerProps?: ScreenHeaderProps;
  bottomToolbar?: ReactNode;
  hideToolbar?: boolean;
  disableLocationPermissionGate?: boolean;
  onReenter?: () => void;
  onEnter?: () => void;
}) => {
  const didInit = useRef(false);
  const { track } = useAnalytics();
  const { updateIsLocationPermissionGateDisabled } = useAppContext();

  return (
    <>
      <Page
        className={`screen ${headerProps ? 'has-header' : 'has-no-header'} ${className}`}
        pageContent={false}
        noToolbar={!!bottomToolbar || hideToolbar}
        onPageInit={() => {
          didInit.current = true;
        }}
        onPageBeforeIn={(page) => {
          requestAnimationFrame(() => {
            updateIsLocationPermissionGateDisabled(disableLocationPermissionGate);
          });
          track('Screen View', {
            url: page.route.url,
            'Screen Name': screenName,
          });
          onEnter?.();
          if (didInit.current && onReenter) {
            onReenter();
          }
        }}
      >
        <Helmet>
          {metaData.title && <title>{metaData.title}</title>}
          {metaData.title && <meta property="og:title" content={metaData.title} />}

          {metaData.description && <meta name="description" content={metaData.description} />}
          {metaData.description && (
            <meta property="og:description" content={metaData.description} />
          )}

          <meta
            property="og:image"
            content={metaData.image ?? 'https://my.gaggl.app/assets/images/icon.png'}
          />
        </Helmet>
        <ScreenHeader {...headerProps} />
        {bottomToolbar}
        {children}
      </Page>
    </>
  );
};

interface ScreenContentProps extends PropsWithChildren {
  maxWidth?: 425;
  onPullToRefresh?: () => Promise<any>;
  infiniteScroll?: {
    hasReachedEnd: boolean;
    onLoadMore: () => Promise<void>;
  };
}
Screen.Content = forwardRef<any, ScreenContentProps>(
  ({ onPullToRefresh, infiniteScroll, maxWidth, children }, ref: any) => {
    const handlePullToRefresh = !!onPullToRefresh
      ? async (done: Function) => {
          await onPullToRefresh();
          done();
        }
      : undefined;

    const allowInfinite = useRef(!!infiniteScroll);
    const [showPreloader, setShowPreloader] = useState(!!infiniteScroll);
    const loadMore = !!infiniteScroll?.onLoadMore
      ? async () => {
          if (!allowInfinite.current) {
            return;
          }

          allowInfinite.current = false;

          await infiniteScroll?.onLoadMore();
          allowInfinite.current = true;
        }
      : undefined;

    useEffect(() => {
      if (infiniteScroll?.hasReachedEnd) {
        setShowPreloader(false);
      }
    }, [infiniteScroll?.hasReachedEnd]);

    return (
      <PageContent
        ref={ref}
        ptr={!!onPullToRefresh}
        ptrMousewheel={!!onPullToRefresh}
        onPtrRefresh={handlePullToRefresh}
        infinite={!!infiniteScroll?.onLoadMore}
        infiniteDistance={50}
        infinitePreloader={showPreloader || !infiniteScroll?.hasReachedEnd}
        onInfinite={loadMore}
      >
        <div className="screen-content">
          {maxWidth ? (
            <div style={{ maxWidth, marginLeft: 'auto', width: '100%', marginRight: 'auto' }}>
              {children}
            </div>
          ) : (
            children
          )}
        </div>
      </PageContent>
    );
  },
);

Screen.BottomToolbar = ({ maxWidth, children }: PropsWithChildren<{ maxWidth?: 425 }>) => {
  return (
    <Toolbar className="screen-bottom-toolbar" bottom>
      {maxWidth ? (
        <div style={{ maxWidth, marginLeft: 'auto', width: '100%', marginRight: 'auto' }}>
          {children}
        </div>
      ) : (
        children
      )}
    </Toolbar>
  );
};

Screen.GagglTitle = () => {
  return (
    <div style={{ width: 60, transform: 'translateY(4px)' }}>
      <GagglLogo />
    </div>
  );
};
