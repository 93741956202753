import { QueryClient } from '@tanstack/react-query';

import { fetchCurrentEntertainer } from '@queries/EntertainerQueries';
import { fetchCurrentPromoter } from '@queries/PromoterQueries';
import { fetchCurrentUser, fetchTokenIsValid } from '@queries/UserQueries';

const defaultStaleMinutes = 5;
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: 1000 * 60 * defaultStaleMinutes,
    },
  },
});

export const initializeQueryClient = async () => {
  const token = localStorage.getItem('token');
  if (token) {
    const tokenIsValid = await fetchTokenIsValid(queryClient);

    if (tokenIsValid) {
      await Promise.all([
        fetchCurrentUser(queryClient),
        fetchCurrentPromoter(queryClient),
        fetchCurrentEntertainer(queryClient),
      ]);
    }
  }
};
