import { ComponentProps, FC } from 'react';
import ReactPhoneNumberInput from 'react-phone-number-input/input';

import { Input } from './Input';

interface PhoneInputProps
  extends Pick<ComponentProps<typeof ReactPhoneNumberInput>, 'onChange' | 'value' | 'disabled'> {
  placeholder?: string;
  className?: string;
}

export const PhoneInput: FC<PhoneInputProps> = ({ placeholder = 'Phone Number', ...props }) => {
  return (
    <ReactPhoneNumberInput
      placeholder={placeholder}
      country="US"
      inputComponent={Input}
      {...props}
    />
  );
};
