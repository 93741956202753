import { useQueryClient } from '@tanstack/react-query';
import { AccordionContent, List, ListItem } from 'framework7-react';
import { FC, useEffect, useMemo, useRef, useState } from 'react';

import { useAppContext } from '@contexts/AppContext';

import {
  Entertainer,
  EntertainerRank,
  useCurrentEntertainerQuery,
  useTopEntertainersQuery,
} from '@queries/EntertainerQueries';

import {
  CritiqueDocumentMutationPayload,
  useCritiqueDocumentMutation,
  useTopEntertainersOptimisticUpdate,
} from '@mutations/CritiqueMutations';

import { useAnalytics } from '@hooks/useAnalytics';
import { CurrentCity, useCitiesQuery } from '@hooks/useCurrentUserLocation';
import { useShareLink } from '@hooks/useShareLink';

import { historyStack, useNavigate, useStoredBackUrl } from '@router/index';

import { openExternalUrl } from '@utilities/openExternalUrl';

import Button from '@components/buttons/Button';
import IconButton from '@components/buttons/IconButton';
import { ChipRadioOptions } from '@components/chips/ChipRadioOptions';
import { Input } from '@components/form/Input';
import { SearchField } from '@components/form/SearchField/SearchField';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import { VerifiedIcon } from '@components/icons/VerifiedIcon';
import { EmptyState } from '@components/layout/EmptyState/EmptyState';
import { LinkBanner } from '@components/layout/LinkBanner/LinkBanner';
import { PopoverWrapper } from '@components/layout/Popover/PopoverWrapper';
import { Popup } from '@components/layout/Popup/Popup';
import { Screen } from '@components/layout/Screen';
import { Skeleton } from '@components/layout/Skeleton/Skeleton';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';
import { PrimaryCritiqueCountChips } from '@components/overlays/CritiqueOverlay/PrimaryCritiqueCountChips';

import { useAuthGate } from '@screens/LogInScreen/AuthGateContext';

import { ClapIcon } from './ClapIcon';
import { EntertainerCritiquePopup } from './EntertainerCritiquePopup';

export const RankingList = <
  T extends {
    _id: string;
    title: string;
    isClaimed?: boolean;
    tags?: {
      like: string[];
      dislike: string[];
      neutral: string[];
    };
    rank: number;
    previousRank?: number;
    primaryRating: string;
    like?: { label: string }[];
  },
>({
  isLoading,
  list,
  onClick,
  onClickRating,
}: {
  isLoading: boolean;
  list: T[];
  onClick: (document: T) => void;
  onClickRating: (
    documentId: string,
  ) => (payload: CritiqueDocumentMutationPayload) => Promise<void>;
}) => {
  if (isLoading) {
    return (
      <div className="d-grid gap-2">
        <Skeleton height={40} />
        <Skeleton height={40} />
        <Skeleton height={40} />
        <Skeleton height={40} />
        <Skeleton height={40} />
        <Skeleton height={40} />
        <Skeleton height={40} />
        <Skeleton height={40} />
        <Skeleton height={40} />
      </div>
    );
  }

  const getSubtitle = (listItem: T): string | undefined => {
    if (listItem.tags?.like?.length || listItem.like?.length) {
      return listItem.tags?.like?.length
        ? listItem.tags.like.join(' • ')
        : listItem.like.map(({ label }) => label).join(' • ');
    }

    return undefined;
  };

  return (
    <List
      dividersIos
      mediaList
      className="mt-2 mb-2"
      style={{ marginLeft: '-.5rem', marginRight: '-1rem' }}
    >
      {list.map((listItem) => (
        <ListItem key={listItem._id} onClick={() => onClick(listItem)}>
          <div slot="title" style={{ lineHeight: '32px' }}>
            <div className="d-flex gap-1 align-items-center">
              <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{listItem.title}</div>
              <div className="d-flex">{listItem.isClaimed && <VerifiedIcon />}</div>
            </div>
          </div>

          {getSubtitle(listItem) && <div slot="footer">{getSubtitle(listItem)}</div>}

          <div slot="media">
            <div style={{ position: 'relative' }}>
              {listItem.previousRank && listItem.rank !== listItem.previousRank && (
                <div
                  className={listItem.rank < listItem.previousRank ? 'color-green' : 'color-red'}
                  style={{
                    position: 'absolute',
                    left: '-.75rem',
                    color: 'var(--f7-theme-color)',
                  }}
                >
                  <FontAwesomeIcon
                    name={listItem.rank < listItem.previousRank ? 'caret-up' : 'caret-down'}
                  />
                </div>
              )}
              <strong
                className="d-block text-align-center"
                style={{ width: 36 }}
              >{`${listItem.rank}`}</strong>
            </div>
          </div>
          <div slot="after">
            <PrimaryCritiqueCountChips
              hideCounts
              disableOverlay
              controlSelectedRating
              defaultValue={listItem.primaryRating}
              counts={{
                like: 0,
                dislike: 0,
                neutral: 0,
              }}
              onMoreClick={() => onClick(listItem)}
              onSubmit={onClickRating(listItem._id)}
            />
          </div>
        </ListItem>
      ))}
    </List>
  );
};

export const EntertainerRankingsContent = ({
  city: currentCity,
  type,
  back,
}: {
  city: { _id: string; name: string };
  type: Entertainer['type'];
  back: string;
}) => {
  const { userIsLoggedIn } = useAppContext();
  const navigate = useNavigate();
  const { createAuthGateHandler } = useAuthGate();
  const { track } = useAnalytics();
  const { entertainer: currentEntertainer } = useCurrentEntertainerQuery();
  const { topEntertainersAreReady, topEntertainers, totalEntertainers, refetchTopEntertainers } =
    useTopEntertainersQuery(currentCity._id, type, userIsLoggedIn);
  const { handleTopEntertainersOptimisticUpdate } = useTopEntertainersOptimisticUpdate(
    currentCity._id,
    type,
    userIsLoggedIn,
  );

  const [selectedEntertainer, setSelectedEntertainer] = useState<EntertainerRank>();

  const [searchQuery, setSearchQuery] = useState('');
  const entertainersList = useMemo(() => {
    if (!topEntertainers?.length) {
      return [];
    }

    if (!searchQuery) {
      return topEntertainers;
    }

    const searchQueryLowercased = searchQuery.toLowerCase();

    return topEntertainers.filter((entertainer) =>
      entertainer.title.toLowerCase().includes(searchQueryLowercased),
    );
  }, [topEntertainers, searchQuery]);

  useEffect(() => {
    refetchTopEntertainers();
  }, []);

  const { critiqueDocumentAsync } = useCritiqueDocumentMutation();
  const handleClickRating =
    (entertainerId: string) => async (payload: CritiqueDocumentMutationPayload) => {
      track('Click rate entertainer', {
        ...payload,
        entertainerId,
      });
      createAuthGateHandler(async () => {
        handleTopEntertainersOptimisticUpdate(entertainerId, {
          primaryCritique: payload.primaryCritique,
        });
        await critiqueDocumentAsync({
          ...payload,
          documentType: 'entertainers',
          documentId: entertainerId,
        });
      });
    };

  const handleUpdateStrengths =
    (entertainerId: string) => async (payload: CritiqueDocumentMutationPayload) => {
      track('Update strengths', {
        ...payload,
        entertainerId,
      });
      createAuthGateHandler(async () => {
        handleTopEntertainersOptimisticUpdate(entertainerId, {
          like: payload.like,
        });
        await critiqueDocumentAsync({
          ...payload,
          documentType: 'entertainers',
          documentId: entertainerId,
        });
      });
    };

  const handleUpdateImprovements =
    (entertainerId: string) => async (payload: CritiqueDocumentMutationPayload) => {
      track('Update improvements', {
        ...payload,
        entertainerId,
      });
      createAuthGateHandler(async () => {
        handleTopEntertainersOptimisticUpdate(entertainerId, {
          neutral: payload.neutral,
        });
        await critiqueDocumentAsync({
          ...payload,
          documentType: 'entertainers',
          documentId: entertainerId,
        });
      });
    };

  const handleLoadMore = () => {
    track('Click load more');
    createAuthGateHandler(async () => {
      await refetchTopEntertainers();
    });
  };

  const handleNavigateToEntertainer = (entertainer: EntertainerRank) => {
    navigate(`/noauth/entertainer/${entertainer.bookingHandle}`, {
      state: {
        back,
      },
    });
  };

  const handleEntertainerOnboard = async () => {
    track('Click entertainer onboarding');
    createAuthGateHandler(async () => {
      navigate(`/entertainer/onboarding`, {
        state: {
          back,
        },
      });
    });
  };

  const introMessage = `Check out the current leaderboard for drag performers in ${currentCity?.name}. View the entertainer profiles and use the buttons to update your rating for each entertainer and support your favorites!`;

  const { didCopy, shareLink } = useShareLink();
  const handleShareClick = async () => {
    track('Click share');
    await shareLink({
      url:
        currentCity.name === 'Denver'
          ? `https://dragratings.com/denver`
          : `https://my.gaggl.app/noauth/entertainers/${type}/${currentCity._id}/rankings`,
    });
  };

  if (!topEntertainersAreReady) {
    return (
      <div className="d-grid gap-2">
        <Skeleton height={24} />
        <Skeleton height={120} />
        <Skeleton height={24} />
        <Skeleton height={40} />
        <Skeleton height={40} />
        <Skeleton height={40} />
        <Skeleton height={40} />
        <Skeleton height={40} />
      </div>
    );
  }

  return (
    <>
      <TitleToolbar
        className="mb-0"
        text={currentCity ? `Drag Performers in ${currentCity?.name}` : <Skeleton height={25} />}
        size="md"
        action={
          <Button
            variant="smooth"
            roundness="rounded"
            size="sm"
            color="neutral"
            startIcon={
              didCopy ? <FontAwesomeIcon name="check" /> : <FontAwesomeIcon name="share-square" />
            }
            className="opacity-90"
            onClick={handleShareClick}
          >
            {didCopy ? 'Copied!' : 'Share'}
          </Button>
        }
      />
      {/* <TitleToolbar
          text={currentCity ? `Drag Performers` : <Skeleton height={25} />}
          className="title-toolbar-welcome"
        /> */}
      {/* <TitleToolbar text="Your Rankings 🏆" size="md" /> */}
      <p className="mt-1">{introMessage}</p>
      {!currentEntertainer && (
        <>
          <LinkBanner
            icon={
              <IconButton className="mx-auto" badge variant="smooth" color="neutral" size="lg">
                <FontAwesomeIcon name="envelope" />
              </IconButton>
            }
            title="Are you a drag artist?"
            description="Join the leaderboard and get a free page for tips and bookings!"
            onClick={handleEntertainerOnboard}
          />
          <div className="my-2 d-flex gap-1">
            <div>
              <VerifiedIcon />
            </div>
            <div>
              Verified performers can be booked directly and get notified when local producers are
              looking to hire entertainment.
            </div>
          </div>
        </>
      )}
      <TitleToolbar
        text={
          <div className="d-flex gap-2 align-items-center">
            <span>Leaderboard</span>
            <PopoverWrapper
              PopoverContent={() => (
                <div className="px-3">
                  <p>
                    Drag performers are ranked by the number of ratings they receive from the
                    community as well as the type of ratings. The more positive ratings, the higher
                    the entertainer is ranked!
                  </p>
                  <p>The up and down arrows indicate the change in rank since yesterday.</p>
                </div>
              )}
            >
              <FontAwesomeIcon name="info-circle" />
            </PopoverWrapper>
          </div>
        }
        size="sm"
        action={
          topEntertainersAreReady ? (
            <span>{`${totalEntertainers} Total`}</span>
          ) : (
            <Skeleton height={16} />
          )
        }
      />
      {!!entertainersList?.length ? (
        <>
          {topEntertainers?.length && topEntertainers.length > 10 && (
            <>
              <Input
                placeholder="Search leaderboard"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              {searchQuery && (
                <Button
                  variant="flat"
                  color="neutral"
                  startIcon={<FontAwesomeIcon name="times" />}
                  onClick={() => setSearchQuery('')}
                >
                  Clear
                </Button>
              )}
            </>
          )}
          <RankingList
            isLoading={!topEntertainersAreReady}
            list={entertainersList}
            onClick={(entertainer) => setSelectedEntertainer(entertainer)}
            onClickRating={handleClickRating}
          />
          {topEntertainers?.length && topEntertainers.length < totalEntertainers && (
            <Button variant="outlined" color="neutral" roundness="rounded" onClick={handleLoadMore}>
              Show All Drag Performers
            </Button>
          )}
        </>
      ) : (
        <EmptyState
          title="No Performers Yet"
          text={`Be the first to showcase your talent in ${currentCity.name}! Join now and start connecting the local drag community.`}
          button={{
            text: 'Create Your Profile',
            onClick: handleEntertainerOnboard,
          }}
        />
      )}
      <EntertainerCritiquePopup
        selectedEntertainer={selectedEntertainer}
        onClickRating={handleClickRating}
        onUpdateStrengths={handleUpdateStrengths}
        onUpdateImprovements={handleUpdateImprovements}
        onClickViewProfile={handleNavigateToEntertainer}
        onClose={() => setSelectedEntertainer(undefined)}
      />
    </>
  );
};

export const EntertainerRankingsScreen = ({
  cityId,
  type,
  back: initialBack,
}: {
  cityId: string;
  type: Entertainer['type'];
  back?: string;
}) => {
  const { userIsLoggedIn } = useAppContext();
  const { storedBackUrl } = useStoredBackUrl();
  const { cities } = useCitiesQuery();
  const { track } = useAnalytics();
  const { refetchTopEntertainers } = useTopEntertainersQuery(cityId, type, userIsLoggedIn);

  const currentCity = useMemo<CurrentCity | undefined>(() => {
    if (!cities?.length || !cityId) {
      return null;
    }

    return cities.find((city) => city._id === cityId);
  }, [cities, cityId]);

  const metaTitle = `${currentCity?.name ?? 'Local'} Drag Leaderboard | Gaggl`;
  const introMessage = `Check out the current leaderboard for drag performers in ${currentCity?.name ?? 'your city'}. View your the entertainer profiles and use the buttons to update your rating for each entertainer and support your favorites!`;

  const back = initialBack ?? storedBackUrl;

  return (
    <Screen
      name="Entertainer Rankings Screen"
      hideToolbar
      disableLocationPermissionGate
      headerProps={{
        title: <Screen.GagglTitle />,
        back: back ? { to: back } : undefined,
        right: (
          <Button
            variant="outlined"
            roundness="rounded"
            size="sm"
            color="neutral"
            startIcon={<FontAwesomeIcon className="fs-6" faStyle="fab" name="instagram" />}
            className="opacity-90"
            onClick={() => {
              track('Click follow on Instagram');
              openExternalUrl('https://www.instagram.com/gaggl.app/');
            }}
          >
            gaggl.app
          </Button>
        ),
      }}
      onReenter={() => {
        refetchTopEntertainers();
      }}
      metaData={{
        title: metaTitle,
        description: introMessage,
        image: 'https://my.gaggl.app/assets/images/drag-leaderboard.png',
      }}
    >
      <Screen.Content maxWidth={425}>
        {currentCity && (
          <EntertainerRankingsContent
            city={currentCity}
            type={type}
            back={`/noauth/entertainers/${type}/${cityId}/rankings`}
          />
        )}
      </Screen.Content>
    </Screen>
  );
};
