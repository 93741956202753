import { Dispatch, FC, SetStateAction } from 'react';

import { FormLabel } from '@components/form/FormLabel';
import { Input, InputProps } from '@components/form/Input';

interface ContactProperties {
  instagram: string;
  facebook: string;
  email: string;
}

interface SharedProps {
  variant?: InputProps['variant'];
  contact: Partial<ContactProperties>;
  setContact: (updatedValue: Partial<ContactProperties>) => void;
}

const Instagram: FC<SharedProps> = ({ variant = 'default', contact, setContact }) => (
  <div>
    <FormLabel text="Instagram" />
    <Input
      type="text"
      placeholder="eg. your.ig.handle"
      variant={variant}
      defaultValue={contact.instagram ?? ''}
      onChange={(e) =>
        setContact({
          ...contact,
          instagram: e.target.value,
        })
      }
    />
  </div>
);

const Facebook: FC<SharedProps> = ({ variant = 'default', contact, setContact }) => (
  <div>
    <FormLabel text="Facebook" />
    <Input
      type="text"
      placeholder="eg. facebook.handle.123"
      variant={variant}
      defaultValue={contact.facebook ?? ''}
      onChange={(e) =>
        setContact({
          ...contact,
          facebook: e.target.value,
        })
      }
    />
  </div>
);
const Email: FC<SharedProps> = ({ variant = 'default', contact, setContact }) => (
  <div>
    <FormLabel text="Email" />
    <Input
      type="text"
      placeholder="eg. yourname@example.com"
      variant={variant}
      defaultValue={contact.email ?? ''}
      onChange={(e) =>
        setContact({
          ...contact,
          email: e.target.value,
        })
      }
    />
  </div>
);

export const ContactFields = { Instagram, Facebook, Email };
