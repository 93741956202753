import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FC, useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { isValidPhoneNumber } from 'react-phone-number-input';

import * as dayjs from 'dayjs';
import { isEmpty, keyBy, sortBy } from 'lodash';

import API from '@api/API';

import {
  Entertainer,
  EntertainerType,
  TYPES_RECORD,
  UnauthorizedPublicEntertainer,
  useCurrentEntertainerQuery,
  useEntertainerByBookingHandleQuery,
} from '@queries/EntertainerQueries';

import { CurrentCity, useCitiesQuery, useCurrentUserCity } from '@hooks/useCurrentUserLocation';

import { useHasBack, useNavigate, useParams } from '@router/index';

import { mapStringToParagraphs } from '@utilities/mapStringToParagraphs';

import Button from '@components/buttons/Button';
import { DatePicker } from '@components/form/DatePicker';
import { FormLabel } from '@components/form/FormLabel';
import { ImageUploadField } from '@components/form/ImageUploadField';
import { Input } from '@components/form/Input';
import { PhoneInput } from '@components/form/PhoneInput';
import { Select } from '@components/form/Select';
import { Textarea } from '@components/form/Textarea';
import { AsyncImage } from '@components/layout/AsyncImg';
import Card from '@components/layout/Card/Card';
import { EmptyState } from '@components/layout/EmptyState/EmptyState';
import { List, ListItemProps } from '@components/layout/List/List';
import { PopoverWrapper } from '@components/layout/Popover/PopoverWrapper';
import { Screen } from '@components/layout/Screen/Screen';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import { GagglLogo } from '../../assets/GagglLogo';
import { CitiesField } from './components/CitiesField';
import { ContactFields } from './components/ContactFields';

const DRAG_QUEEN_SERVICES = [
  'Brunches',
  'Philanthropy Functions',
  'School functions',
  'Key Note Speaking',
  'All Ages Events',
  'Makeup Classes',
  'Birthdays',
  'Company Parties',
  'Bingos',
  'Weddings (Performer, Officiate, guest speaker, party host)',
  'Live Singing',
  'Panels',
  'Drag Queen Story Hour',
  'Model/TV Appearances',
  'Bachelor/Bachelorette Parties',
  'Produce Endorsement',
  'Charity Functions',
  'Party Host',
  'Drag Performances',
  'Impersonations/illusions',
  'Makeup for Special Events',
  'Private Events',
  'Holiday and Special Events',
  'Guest Judge',
];

const ENABLE_MEDIA = false;

type CreateBookingRequestPayload = Partial<{
  cityId: string;
  contact: Partial<{
    name: string;
    email: string;
    phone: string;
  }>;
  details: Partial<{
    startDate: string;
    startTime: string;
    venue: {
      title: string;
    };
  }>;
  performance: Partial<{
    duration: string;
    type: string;
    specialRequests: string;
    additionalQuestions?: string;
  }>;
  technical: Partial<{
    soundSystem: string;
    dressingRoom: string;
    lightingSetup: string;
  }>;
}>;

const useCreateBookingRequestMutation = (entertainerId: string) => {
  const queryClient = useQueryClient();

  const { isPending: isSubmitting, mutateAsync: createBookingRequestAsync } = useMutation({
    mutationFn: (payload: CreateBookingRequestPayload) => {
      return API.post(`/v1/entertainers/${entertainerId}/book`, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: useCurrentEntertainerQuery.queryKey() });
    },
  });

  return {
    isSubmitting,
    createBookingRequestAsync,
  };
};

const YES_NO_OPTIONS = [
  { label: '--', value: '' },
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
];

interface BookingRequestFormProps {
  entertainer?: UnauthorizedPublicEntertainer;
  onSave?: () => void;
}
export const BookingRequestForm: FC<BookingRequestFormProps> = ({ entertainer, onSave }) => {
  const navigate = useNavigate();
  const cityOptions = useMemo(
    () => entertainer?.cities?.map((city) => ({ value: city._id, label: city.name })),
    [entertainer],
  );

  const [bookingRequestProperties, setBookingRequestProperties] = useState<
    Partial<{
      cityId: string;
      contact: Partial<{
        name: string;
        email: string;
        phone: string;
      }>;
      details: Partial<{
        startDate: string;
        startTime: string;
        venue: {
          title: string;
        };
      }>;
      performance: Partial<{
        duration: string;
        type: string;
        specialRequests: string;
        additionalQuestions: string;
      }>;
      technical: Partial<{
        soundSystem: string;
        dressingRoom: string;
        lightingSetup: string;
      }>;
    }>
  >({
    cityId: cityOptions?.[0]?.value,
  });

  const { isSubmitting: isCreatingProfile, createBookingRequestAsync } =
    useCreateBookingRequestMutation(entertainer?._id);
  const [submittedMessageIsVisible, setSubmittedMessageIsVisible] = useState(false);

  const isSubmitting = isCreatingProfile;
  const canSubmit =
    !isSubmitting &&
    Boolean(
      bookingRequestProperties.cityId &&
        bookingRequestProperties.contact?.name &&
        bookingRequestProperties.contact?.email &&
        isValidPhoneNumber(bookingRequestProperties.contact?.phone ?? '') &&
        bookingRequestProperties.details?.startDate &&
        bookingRequestProperties.details?.startTime &&
        bookingRequestProperties.details?.venue?.title &&
        bookingRequestProperties.performance?.duration &&
        bookingRequestProperties.performance?.type &&
        bookingRequestProperties.performance?.specialRequests &&
        bookingRequestProperties.technical?.soundSystem &&
        bookingRequestProperties.technical?.dressingRoom &&
        bookingRequestProperties.technical?.lightingSetup,
    );

  const startDateTime = useMemo(() => {
    const startDate: string | undefined = bookingRequestProperties?.details?.startDate;
    const startTime: string | undefined = bookingRequestProperties?.details?.startTime;

    return `${startDate?.toString()} ${startTime?.toString()}`.trim();
  }, [bookingRequestProperties]);

  const handleSubmitProfile = async () => {
    if (canSubmit) {
      await createBookingRequestAsync(bookingRequestProperties as CreateBookingRequestPayload);
      await onSave?.();
      setSubmittedMessageIsVisible(true);
    }
  };

  if (submittedMessageIsVisible) {
    return (
      <div style={{ maxWidth: 425, margin: '0 auto' }}>
        <EmptyState
          title="Booking Request Sent"
          text={`Your booking request has been sent to ${entertainer.title}.`}
          // text={`Your booking request has been sent to ${entertainer.title}. Set up your profile to discover and critique local queer events.`}
          // button={{
          //   text: 'Setup Gaggl Profile',
          //   onClick: () => {
          //     navigate('/profile');
          //   },
          // }}
        />
      </div>
    );
  }

  return (
    <div style={{ maxWidth: 425, margin: '0 auto' }}>
      <TitleToolbar text={`Book ${entertainer?.title}`} />

      {entertainer?.avatar && (
        <Card>
          <AsyncImage src={entertainer?.avatar} />
        </Card>
      )}

      <TitleToolbar text="Contact Information" size="md" />
      <div className="grid gap-2">
        <div>
          <FormLabel text="Contact Name *" />
          <Input
            onChange={(e) =>
              setBookingRequestProperties((prevValue) => ({
                ...prevValue,
                contact: {
                  ...(prevValue?.contact ?? {}),
                  name: e.target.value,
                },
              }))
            }
          />
        </div>
        <div>
          <FormLabel text="Contact Email *" />
          <Input
            onChange={(e) =>
              setBookingRequestProperties((prevValue) => ({
                ...prevValue,
                contact: {
                  ...(prevValue?.contact ?? {}),
                  email: e.target.value,
                },
              }))
            }
          />
        </div>
        <div>
          <FormLabel text="Contact Phone *" />
          <PhoneInput
            disabled={false}
            placeholder=""
            onChange={(updatedPhoneNumber) =>
              setBookingRequestProperties((prevValue) => ({
                ...prevValue,
                contact: { ...(prevValue?.contact ?? {}), phone: updatedPhoneNumber },
              }))
            }
          />
        </div>
      </div>

      <TitleToolbar text="Event Details" size="md" />
      <div className="grid gap-2">
        <div>
          <FormLabel text="Event time *" />
          <DatePicker
            showTimeSelect
            direction="bottom"
            value={startDateTime}
            onChange={(updatedDate) =>
              setBookingRequestProperties((prevValue) => ({
                ...prevValue,
                details: {
                  ...(prevValue?.details ?? {}),
                  startDate: dayjs(updatedDate).format('YYYY-MM-DD'),
                  startTime: dayjs(updatedDate).format('HH:mm'),
                },
              }))
            }
          />
        </div>
        <div>
          <FormLabel text="City *" />
          <Select
            options={cityOptions}
            value={bookingRequestProperties?.cityId ?? ''}
            onChange={(e) =>
              setBookingRequestProperties((prevValue) => ({
                ...prevValue,
                cityId: e.target.value,
              }))
            }
          />
        </div>

        <div>
          <FormLabel text="Venue *" />
          <Input
            onChange={(e) =>
              setBookingRequestProperties((prevValue) => ({
                ...prevValue,
                details: {
                  ...(prevValue?.details ?? {}),
                  venue: {
                    ...(prevValue?.details?.venue ?? {}),
                    title: e.target.value,
                  },
                },
              }))
            }
          />
        </div>
      </div>

      <TitleToolbar text="Performance Details" size="md" />
      <div className="grid gap-2">
        <div>
          <FormLabel text="Performance Duration (e.g., 1 hour) *" />
          <Input
            onChange={(e) =>
              setBookingRequestProperties((prevValue) => ({
                ...prevValue,
                performance: {
                  ...(prevValue?.performance ?? {}),
                  duration: e.target.value,
                },
              }))
            }
          />
        </div>

        <div>
          <FormLabel text="Details and Type of Performance (e.g., lip-sync, live singing, hosting) *" />
          <Textarea
            onChange={(updatedPerformanceType) =>
              setBookingRequestProperties((prevValue) => ({
                ...prevValue,
                performance: {
                  ...(prevValue?.performance ?? {}),
                  type: updatedPerformanceType,
                },
              }))
            }
          />
        </div>

        <div>
          <FormLabel text="Special Requests for Performance (e.g., specific songs, themes) *" />
          <Textarea
            onChange={(updatedSpecialRequests) =>
              setBookingRequestProperties((prevValue) => ({
                ...prevValue,
                performance: {
                  ...(prevValue?.performance ?? {}),
                  specialRequests: updatedSpecialRequests,
                },
              }))
            }
          />
        </div>

        <div>
          <FormLabel text={`Additional Questions for ${entertainer?.title}`} />
          <Textarea
            onChange={(updatedSpecialRequests) =>
              setBookingRequestProperties((prevValue) => ({
                ...prevValue,
                performance: {
                  ...(prevValue?.performance ?? {}),
                  additionalQuestions: updatedSpecialRequests,
                },
              }))
            }
          />
        </div>
      </div>

      <TitleToolbar text="Technical Requirements" size="md" />
      <div className="grid gap-2">
        <div>
          <FormLabel text="Will a sound system be provided? *" />
          <Select
            options={YES_NO_OPTIONS}
            value={bookingRequestProperties?.technical?.soundSystem}
            onChange={(e) =>
              setBookingRequestProperties((prevValue) => ({
                ...prevValue,
                technical: {
                  ...(prevValue?.technical ?? {}),
                  soundSystem: e.target.value,
                },
              }))
            }
          />
        </div>
        <div>
          <FormLabel text="Does the space have a dressing room? *" />
          <Select
            options={YES_NO_OPTIONS}
            value={bookingRequestProperties?.technical?.dressingRoom}
            onChange={(e) =>
              setBookingRequestProperties((prevValue) => ({
                ...prevValue,
                technical: {
                  ...(prevValue?.technical ?? {}),
                  dressingRoom: e.target.value,
                },
              }))
            }
          />
        </div>
        <div>
          <FormLabel text="Does the space have a lighting setup? *" />
          <Select
            options={YES_NO_OPTIONS}
            value={bookingRequestProperties?.technical?.lightingSetup}
            onChange={(e) =>
              setBookingRequestProperties((prevValue) => ({
                ...prevValue,
                technical: {
                  ...(prevValue?.technical ?? {}),
                  lightingSetup: e.target.value,
                },
              }))
            }
          />
        </div>
      </div>

      <div className="mt-4">
        {/** Checkbox: Send me sms updates about this booking request */}
        <Button roundness="rounded" disabled={!canSubmit} onClick={handleSubmitProfile}>
          {'Send Booking Request '}
        </Button>
      </div>
    </div>
  );
};

export const BookingRequestScreen: FC<{ back?: string }> = ({ back }) => {
  const { bookingHandle } = useParams<{ bookingHandle: string }>();
  const { entertainer } = useEntertainerByBookingHandleQuery(bookingHandle);

  return (
    <Screen
      name="Create Booking Request Screen"
      disableLocationPermissionGate
      hideToolbar
      headerProps={{
        title: <Screen.GagglTitle />,
        back: back ? { to: back } : undefined,
      }}
    >
      <Screen.Content>
        {entertainer && <BookingRequestForm entertainer={entertainer} />}
      </Screen.Content>
    </Screen>
  );
};
