import { useDevice } from './useDevice';

const isIOS = () => {
  return /iPad|iPhone|iPod/.test(navigator.userAgent);
};

export const useCanInstallIOSApp = () => {
  const { isWeb } = useDevice();
  const canInstallIOSApp = isWeb && isIOS();

  return { canInstallIOSApp };
};
