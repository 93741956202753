import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { keyBy, uniq } from 'lodash';

import { CurrentCity } from '@hooks/useCurrentUserLocation';

import Button from '@components/buttons/Button';
import { FormLabel } from '@components/form/FormLabel';
import { Input } from '@components/form/Input';
import { List } from '@components/layout/List/List';
import { PopoverWrapper } from '@components/layout/Popover/PopoverWrapper';

export const MultiSelectField: FC<{
  name?: string;
  label?: string;
  defaultValue: string[];
  options: { value: string; label: string }[];
  onChange: (updatedValues: string[]) => void;
  allowSearch?: boolean;
}> = ({ label, defaultValue, options, onChange, allowSearch }) => {
  const [selectedRecord, setSelectedRecord] = useState<Record<string, boolean>>(
    Object.fromEntries(defaultValue.map((value) => [value, true])),
  );

  const handleCheckboxChange = useCallback(
    (value: string) => (updatedCheckedValue: boolean) => {
      setSelectedRecord((prev) => {
        const updatedRecord = { ...prev, [value]: updatedCheckedValue };

        onChange(
          Object.entries(updatedRecord)
            .filter(([, checked]) => checked)
            .map(([value]) => value),
        );

        return updatedRecord;
      });
    },
    [],
  );

  const inputFieldValue = useMemo(() => {
    return options
      .filter(({ value }) => selectedRecord[value])
      .map(({ label }) => label)
      .sort()
      .join(', ');
  }, [options, selectedRecord]);

  const PopoverContent = ({ onClose }: { onClose: () => void }) => {
    const [searchValue, setSearchValue] = useState('');

    const filteredOptions = useMemo(() => {
      const searchValueLowercased = searchValue.trim().toLowerCase();
      return options.filter((option) => option.label.toLowerCase().includes(searchValueLowercased));
    }, [options, searchValue]);

    return (
      <div>
        {allowSearch && (
          <div className="" style={{ borderBottom: '1px solid rgba(255,255,255,.2)' }}>
            <Input
              placeholder="Search"
              style={{ background: 'transparent' }}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
        )}
        <div style={{ height: 200, overflow: 'auto' }}>
          <List
            listItems={
              filteredOptions.length
                ? filteredOptions.map((options) => ({
                    hasCheckbox: true,
                    defaultChecked: selectedRecord[options.value],
                    value: options.value,
                    title: options.label,
                    onCheckboxChange: handleCheckboxChange(options.value),
                  }))
                : [{ title: 'No matches' }]
            }
          ></List>
        </div>
        <div className="p-2">
          <Button roundness="rounded" onClick={onClose}>
            Close
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div>
      {label && <FormLabel text={label} />}
      <PopoverWrapper PopoverContent={PopoverContent}>
        <Input value={inputFieldValue} />
      </PopoverWrapper>
    </div>
  );
};

export const CitiesField: FC<{
  cities: CurrentCity[];
  cityIds?: string[];
  onChange: (updatedCityIds: string[]) => void;
}> = ({ cities, cityIds: initialCityIds = [], onChange }) => {
  return (
    <MultiSelectField
      label="City *"
      defaultValue={initialCityIds}
      options={cities.map((city) => ({
        value: city._id,
        label: city.name,
      }))}
      onChange={onChange}
    />
  );
};
