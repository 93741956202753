import { MutableRefObject, useEffect, useState } from 'react';

export function useIsVisible(ref: MutableRefObject<HTMLElement>) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));

    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return { isVisible: isIntersecting };
}
