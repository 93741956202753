import mixpanel from 'mixpanel-browser';

import { Entertainer } from '@queries/EntertainerQueries';
import { CurrentPromoter } from '@queries/PromoterQueries';
import { CurrentUser } from '@queries/UserQueries';

const { REACT_APP_MIXPANEL_TOKEN = '' } = process.env;

if (REACT_APP_MIXPANEL_TOKEN) {
  mixpanel.init(REACT_APP_MIXPANEL_TOKEN, { persistence: 'localStorage' });
} else {
  mixpanel.init('MOCK_MIXPANEL_TOKEN');
  mixpanel.disable();
}

const getCurrentIpAddress = async (): Promise<string | undefined> => {
  const response = await fetch('https://api.ipify.org?format=json');
  const data = await response.json();
  return data.ip;
};

const INTERNAL_IP_ADDRESSES = ['157.245.88.103'];

getCurrentIpAddress().then((ipAddress) => {
  // Disable mixpanel for server IP addresses
  if (INTERNAL_IP_ADDRESSES.includes(ipAddress || '')) {
    mixpanel.disable();
  }
});

const getIdentifierName = (
  user: CurrentUser | undefined,
  promoter: CurrentPromoter | undefined,
  entertainer: Entertainer | undefined,
) => {
  if (user.displayName) {
    return user.displayName;
  }

  if (promoter) {
    return promoter.title;
  }

  if (entertainer) {
    return entertainer.title;
  }

  return 'Unknown User';
};

export const useAnalytics = () => {
  const identify = (
    user: CurrentUser | undefined,
    promoter: CurrentPromoter | undefined,
    entertainer: Entertainer | undefined,
  ) => {
    if (user) {
      mixpanel.identify(user._id);

      mixpanel.people.set({
        $name: getIdentifierName(user, promoter, entertainer),
        $phone: user.phone,
        $ignore: user.isSuperAdmin,
        isPromoter: !!promoter,
      });
    }
  };

  const track = (event: string, properties?: Record<string, any>) => {
    mixpanel.track(event, properties);
  };

  return {
    identify,
    track,
  };
};
