import { FC, useMemo, useState } from 'react';

import * as dayjs from 'dayjs';
import { keyBy } from 'lodash';

import { useCurrentPromoterQuery } from '@queries/PromoterQueries';

import {
  CreateBookingOpportunityMutationPayload,
  useCreateBookingOpportunityMutation,
} from '@mutations/PromoterMutations';

import { useCityOptionsByIds } from '@hooks/useCityOptionsByIds';
import { useCurrentUserCity } from '@hooks/useCurrentUserLocation';

import { useRouter } from '@router/index';

import Button from '@components/buttons/Button';
import { DatePicker } from '@components/form/DatePicker';
import { FormLabel } from '@components/form/FormLabel';
import { Input } from '@components/form/Input';
import { Select } from '@components/form/Select';
import { Textarea } from '@components/form/Textarea';
import { Screen } from '@components/layout/Screen';

interface CreateUpdateBookingOpportunityFormProps {
  cityOptions: { label: string; value: string }[];
  bookingOpportunity?: {
    title: string;
    details: string;
    startDate: string;
    startTime: string;
    venue: {
      _id?: string;
      title: string;
    };
  };
  onSubmit: (payload: CreateBookingOpportunityMutationPayload) => Promise<void>;
}

export const CreateUpdateBookingOpportunityForm: FC<CreateUpdateBookingOpportunityFormProps> = ({
  bookingOpportunity,
  cityOptions,
  onSubmit,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [updatedBookingOpportunityProperties, setUpdatedBookingOpportunityProperties] =
    useState<CreateBookingOpportunityMutationPayload>({
      ...bookingOpportunity,
      cityId: cityOptions?.[0]?.value,
    });

  const startDateTime = useMemo(() => {
    const startDate: string | undefined =
      bookingOpportunity?.startDate ?? updatedBookingOpportunityProperties?.startDate;
    const startTime: string | undefined =
      bookingOpportunity?.startTime ?? updatedBookingOpportunityProperties?.startTime;

    return `${startDate?.toString()} ${startTime?.toString()}`.trim();
  }, [event, updatedBookingOpportunityProperties]);

  const handleChangeImage = (updatedBookingOpportunityImage: string | ArrayBuffer) => {
    setUpdatedBookingOpportunityProperties((prevValue) => ({
      ...prevValue,
      eventImage: updatedBookingOpportunityImage,
    }));
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await onSubmit(updatedBookingOpportunityProperties);
    setIsSubmitting(false);
  };

  return (
    <>
      <div className="grid gap-2 mt-2">
        <div>
          <FormLabel text="Title *" />
          <Input
            defaultValue={bookingOpportunity?.title}
            onChange={(e) =>
              setUpdatedBookingOpportunityProperties((prevValue) => ({
                ...prevValue,
                title: e.target.value,
              }))
            }
          />
        </div>

        <div>
          <FormLabel text="Description *" />
          <Textarea
            defaultValue={bookingOpportunity?.details}
            onChange={(updatedDescription) =>
              setUpdatedBookingOpportunityProperties((prevValue) => ({
                ...prevValue,
                details: updatedDescription,
              }))
            }
          />
        </div>

        <div>
          <FormLabel text="Start *" />
          <DatePicker
            showTimeSelect
            direction="bottom"
            value={startDateTime}
            onChange={(updatedDate) =>
              setUpdatedBookingOpportunityProperties((prevValue) => ({
                ...prevValue,
                startDate: dayjs(updatedDate).format('YYYY-MM-DD'),
                startTime: dayjs(updatedDate).format('HH:mm'),
              }))
            }
          />
        </div>

        <div>
          <FormLabel text="City *" />
          <Select
            options={cityOptions}
            value={updatedBookingOpportunityProperties.cityId}
            onChange={(e) =>
              setUpdatedBookingOpportunityProperties((prevValue) => ({
                ...prevValue,
                cityId: e.target.value,
              }))
            }
          />
        </div>

        <div>
          <FormLabel text="Venue *" />
          <Input
            defaultValue={bookingOpportunity?.venue?.title}
            onChange={(e) =>
              setUpdatedBookingOpportunityProperties((prevValue) => ({
                ...prevValue,
                venue: {
                  title: e.target.value,
                },
              }))
            }
          />
        </div>

        {/* <div>
          <FormLabel text="Drag Performers" />
          Request from local queens
          Multiselect
          <Input
            defaultValue={bookingOpportunity?.venue?.title}
            onChange={(e) =>
              setUpdatedBookingOpportunityProperties((prevValue) => ({
                ...prevValue,
                venue: {
                  title: e.target.value,
                },
              }))
            }
          />
        </div> */}
      </div>
      <div className="mt-4">
        <Button roundness="rounded" disabled={isSubmitting} onClick={handleSubmit}>
          Create Booking Opportunity
        </Button>
      </div>
    </>
  );
};

export const CreateEditBookingOpportunitesScreen = () => {
  const { back } = useRouter();
  const { promoter } = useCurrentPromoterQuery();
  const { createBookingOpportunityAsync } = useCreateBookingOpportunityMutation(promoter?._id);
  const { cityOptionsAreReady, cityOptions } = useCityOptionsByIds(promoter?.cityIds ?? []);

  const handleSubmit = async (payload) => {
    await createBookingOpportunityAsync(payload);
    back();
  };

  return (
    <Screen
      name="Create Booking Opportunity Screen"
      headerProps={{ back: { to: '/manage/promoter' }, title: 'Create Open Booking' }}
      hideToolbar
    >
      <Screen.Content>
        {cityOptionsAreReady && (
          <CreateUpdateBookingOpportunityForm cityOptions={cityOptions} onSubmit={handleSubmit} />
        )}
      </Screen.Content>
    </Screen>
  );
};
