import { useQuery } from '@tanstack/react-query';
import { FC, useEffect, useMemo, useRef, useState } from 'react';

import API from '@api/API';

import { useCurrentEntertainerQuery } from '@queries/EntertainerQueries';

import { useAnalytics } from '@hooks/useAnalytics';
import { useExportComponentToImage } from '@hooks/useExportComponentToImage';
import { convertBase64ToUrl, useShareImage } from '@hooks/useShareLink';

import { mapStringToParagraphs } from '@utilities/mapStringToParagraphs';

import Button from '@components/buttons/Button';
import { Form } from '@components/form/Form';
import { FormLabel } from '@components/form/FormLabel';
import { Input } from '@components/form/Input';
import { Select } from '@components/form/Select';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import { LineAwesomeIcon } from '@components/icons/LineAwesomeIcon';
import { AsyncImage } from '@components/layout/AsyncImg';
import { CopyText } from '@components/layout/CopyText/CopyText';
import { Screen } from '@components/layout/Screen';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import CDRS3PoweredBy from './cdr-s3.png';
import DefaultPoweredBy from './powered-by.png';

const useCurrentEntertainerAvatarBlob = () => {
  const { data, isFetched } = useQuery({
    queryKey: ['currentEntertainer', 'avatar', 'blob'],
    queryFn: () => API.get<{ blob: string }>('/v1/entertainer/me/avatar/blob'),
  });

  return {
    entertainerAvatarBlob: data?.blob,
    isEntertainerAvatarBlobReady: isFetched,
  };
};

const STYLES_RECORD = {
  'cdr-default': {
    label: 'Default',
    overlayImage: DefaultPoweredBy,
    textContainerStyle: {},
    upperTextStyle: {},
    lowerTextStyle: {},
  },
  'cdr-s3': {
    label: 'Contestant Poster',
    overlayImage: CDRS3PoweredBy,
    textContainerStyle: {
      bottom: '28%',
    },
    upperTextStyle: {
      background: 'rgba(3, 100,	149,0.7)',
      padding: '0.25rem .5rem',
      borderRadius: '0.25rem',
    },
    lowerTextStyle: {
      background: 'rgba(3, 100, 149,0.7)',
      padding: '0 .5rem 0.25rem',
      borderBottomLeftRadius: '0.25rem',
      borderBottomRightRadius: '0.25rem',
    },
  },
} as const;
type ImageSetting = keyof typeof STYLES_RECORD;

export const EntertainerCompetitionScreen: FC<{ campaignId: string }> = ({ campaignId }) => {
  const { track } = useAnalytics();

  const { entertainerIsReady, entertainer, competitions = [] } = useCurrentEntertainerQuery();
  const [imageSetting, setImageSetting] = useState<ImageSetting>('cdr-default');
  const imageSettings = STYLES_RECORD[imageSetting];
  const { overlayImage, textContainerStyle, upperTextStyle, lowerTextStyle } = imageSettings;

  const competition = useMemo(() => {
    return competitions.find((competition) => competition._id === campaignId);
  }, [campaignId, competitions]);

  const divRef = useRef<HTMLDivElement>(null);
  const { exportComponentAsJPEG } = useExportComponentToImage();

  const { shareImage } = useShareImage();
  const { entertainerAvatarBlob, isEntertainerAvatarBlobReady } = useCurrentEntertainerAvatarBlob();
  const entertainerAvatarUrlError = false;

  const bookingLinkRoot = 'werqwith.me/';
  const bookingLink = `https://${bookingLinkRoot}${entertainer.bookingHandle}`;

  return (
    <Screen
      hideToolbar
      name="Entertainer Competition Screen"
      headerProps={{
        back: { to: '/manage/entertainer' },
      }}
    >
      <Screen.Content>
        {entertainerIsReady && competition && (
          <>
            <TitleToolbar text={competition.title} />
            <TitleToolbar className="mt-0" text="Tips" size="md" />
            <div className="mb-4">
              <ol className="ps-4">
                <li>
                  If your profile picture is the CDR Contestant Poster, change the "Frame" below
                  from "Default" to "Contestant Poster"
                </li>
                <li>
                  <p>
                    Copy the "werqwith.me" link and add it to your Instagram profile. Then you can
                    also refer your fans to vote by saying "Link in Bio" in your IG stories and
                    posts.
                  </p>
                  <CopyText text={bookingLink} />
                </li>
              </ol>
            </div>
            <div>
              <div className="mx-auto">
                {/** This is the visible element for the user to see and to create the banner */}
                <div
                  ref={divRef}
                  style={{
                    position: 'relative',
                    aspectRatio: 1,
                    display: 'block',
                    overflow: 'hidden',
                  }}
                >
                  <img
                    src={entertainerAvatarBlob ?? entertainer?.avatar}
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                  <img
                    src={overlayImage}
                    style={{
                      position: 'absolute',
                      inset: 0,
                      width: '100%',
                      height: '100%',
                      display: 'block',
                    }}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      bottom: '7%',
                      textAlign: 'center',
                      width: '100%',
                      padding: '1rem',
                      textShadow: '1px 1px 3px rgba(0,0,0,0.7)',
                      ...textContainerStyle,
                      // background:
                      //   'radial-gradient(closest-side, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%)',
                    }}
                  >
                    <div
                      id="primary-text"
                      style={{
                        display: 'flex',
                      }}
                    >
                      <div
                        style={{
                          fontWeight: 700,
                          fontSize: 18,
                          display: 'block',
                          margin: '0 auto',
                          lineHeight: 1,
                          ...upperTextStyle,
                        }}
                      >{`Text "${entertainer?.smsKeyword?.toLocaleUpperCase()}" to 720.734.2336`}</div>
                    </div>
                    <div
                      id="secondary-text"
                      style={{
                        display: 'flex',
                      }}
                    >
                      <div
                        style={{
                          display: 'block',
                          margin: '0 auto',
                          fontWeight: 700,
                          fontSize: 14,
                          lineHeight: 1,
                          ...lowerTextStyle,
                        }}
                      >
                        {`to show your support`}
                      </div>
                    </div>
                  </div>
                  <div
                    className="d-flex gap-1 px-3"
                    style={{
                      position: 'absolute',
                      bottom: 12,
                      right: 0,
                      display: 'block',
                      margin: '0 auto',
                      fontSize: 8,
                      fontWeight: 400,
                      lineHeight: 1,
                      color: '#fff',
                      alignItems: 'center',
                    }}
                  >
                    <FontAwesomeIcon faStyle="fab" name="instagram" />
                    <div>gaggl.app</div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <FormLabel text="Frame" />
              <Select
                value={imageSetting}
                options={Object.entries(STYLES_RECORD).map(([value, { label }]) => ({
                  value,
                  label,
                }))}
                onChange={(e) => setImageSetting(e.target.value as ImageSetting)}
              />
              <Button
                className="mt-1"
                roundness="rounded"
                disabled={!isEntertainerAvatarBlobReady}
                startIcon={<FontAwesomeIcon name={entertainerAvatarUrlError ? 'times' : 'share'} />}
                onClick={() => {
                  exportComponentAsJPEG(divRef, {
                    html2CanvasOptions: {
                      scale: 5,
                    },
                    cb: (uri: string) => {
                      track('Share Image', {
                        imageSetting,
                      });
                      shareImage({ base64Image: uri });
                    },
                  });
                }}
              >
                {entertainerAvatarUrlError ? (
                  'Error creating image'
                ) : (
                  <>{isEntertainerAvatarBlobReady ? 'Share Image' : 'Loading...'}</>
                )}
              </Button>
            </div>

            <TitleToolbar text="Competition Details" size="md" />
            {competition.instructions && mapStringToParagraphs(competition.instructions)}
          </>
        )}
      </Screen.Content>
    </Screen>
  );
};
