import { Badge, Link as F7Link, Toolbar, View, Views, f7ready } from 'framework7-react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useCurrentEntertainerQuery } from '@queries/EntertainerQueries';
import { useCurrentPromoterQuery } from '@queries/PromoterQueries';
import { useCurrentUser, useIsValidTokenQuery, useNotificationsQuery } from '@queries/UserQueries';
import { useMagic } from '@queries/useMagic';

import { useAnalytics } from '@hooks/useAnalytics';
import { useCanInstallIOSApp } from '@hooks/useCanInstallIOSApp';
import { useCurrentUserCity } from '@hooks/useCurrentUserLocation';
import { useDevice } from '@hooks/useDevice';
import { useOneSignal } from '@hooks/useOneSignal';
import { usePWA } from '@hooks/usePWA';
import { useTabbarRoutes } from '@hooks/useTabbarIsVisible';

import { Link, useLocation, useNavigate } from '@router/index';

import { useAppContext } from '../contexts/AppContext';
import './CoreLayout.scss';
import { LocationPermissionGate } from './LocationPermissionGate/LocationPermissionGate';
import { UserScoreNotification } from './UserScoreNotification/UserScoreNotification';

const DeprecatedAppNavigation = () => {
  const location = useLocation();
  const { currentCity } = useCurrentUserCity();
  const routes = useTabbarRoutes();

  if (!routes.map(({ path }) => path).includes(location.pathname)) {
    return <></>;
  }

  const isActiveTab = (path: string) => {
    return path === location.pathname;
  };

  return (
    <div className="tabbar">
      <div className="tabbar-inner">
        {routes
          .filter(({ isVisible = true }) => isVisible)
          .filter(({ requiresEventsEnabled }) =>
            requiresEventsEnabled ? currentCity?.eventsAreEnabled : true,
          )
          .map(({ path, icon, title }) => (
            <div className="tabbar-item" key={path}>
              <Link className={isActiveTab(path) ? 'is-active' : 'is-not-active'} to={path}>
                <div className="tabbar__icon">{icon}</div>
                {title}
              </Link>
            </div>
          ))}
      </div>
    </div>
  );
};

const AppNavigation = () => {
  const { currentCity } = useCurrentUserCity();
  const { promoterIsReady } = useCurrentPromoterQuery();
  const { notifications } = useNotificationsQuery();

  const { userIsLoggedIn } = useAppContext();
  const location = useLocation();
  const routes = useTabbarRoutes();

  const [activeTab, setActiveTab] = useState<string>();

  const isActiveTab = useCallback(
    (path: string) => {
      return path === location.pathname;
    },
    [location.pathname],
  );

  useEffect(() => {
    const updatedActiveTab = routes.find(({ path }) => isActiveTab(path));
    if (updatedActiveTab) {
      setActiveTab(updatedActiveTab.path);
    }
  }, [isActiveTab]);

  const tabBarIsReady = true; // promoterIsReady;
  const { canInstallIOSApp } = useCanInstallIOSApp();
  const { canInstallPWA } = usePWA();
  const hasUnreadServerNotifications = Boolean(
    notifications?.filter(({ readAt }) => !readAt)?.length,
  );
  const showNotificationsBadge = (path: string) =>
    Boolean(
      path === '/notifications' &&
        (hasUnreadServerNotifications || canInstallPWA || canInstallIOSApp),
    );

  return (
    <Toolbar
      className={`f7-tabbar core-tabbar ${tabBarIsReady ? 'tabbar-is-ready' : ''}`}
      tabbar
      bottom
    >
      {routes
        .filter(({ isVisible = true }) => isVisible)
        .filter(({ requiresEventsEnabled }) =>
          requiresEventsEnabled ? currentCity?.eventsAreEnabled : true,
        )
        .map(({ path, icon, title }) => (
          <F7Link
            key={title}
            href={path}
            tabLink="tab-1"
            animate={false} // data-view="#view-2"
            tabLinkActive={activeTab === path}
          >
            <div className="d-flex flex-column align-items-center">
              <div className="tabbar__icon">
                {showNotificationsBadge(path) && <Badge color="red" />}
                {icon}
              </div>
              {title}
            </div>
          </F7Link>
        ))}
    </Toolbar>
  );
};

const isUnauthenticatedRoute = (path: string) => {
  if (!path) {
    return true; // Because path is empty on init
  }

  if (path === '/home') {
    return true;
  }

  if (path.startsWith('/noauth')) {
    return true;
  }

  return ['/login', '/magic-link'].some((route) => path.includes(route));
};

export const CoreLayout = () => {
  const { initOneSignal } = useOneSignal();
  const { currentUserIsReady, currentUser } = useCurrentUser();
  const { promoterIsReady, promoter } = useCurrentPromoterQuery();
  const { entertainerIsReady, entertainer } = useCurrentEntertainerQuery();
  const { tokenIsValidIsReady, tokenIsValid } = useIsValidTokenQuery();
  const { updateToken } = useAppContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { isNativePlatform } = useDevice();

  const { identify } = useAnalytics();

  useEffect(() => {
    if (currentUserIsReady && promoterIsReady && entertainerIsReady) {
      identify(currentUser, promoter, entertainer);
    }
  }, [currentUser, promoter, entertainer]);

  useEffect(() => {
    initOneSignal();
  }, []);

  useEffect(() => {
    if (tokenIsValidIsReady && !tokenIsValid && !isUnauthenticatedRoute(location.pathname)) {
      updateToken(undefined);
      navigate('/', {
        animate: false,
      });
    }
  }, [tokenIsValidIsReady, tokenIsValid]);

  useEffect(() => {
    if (
      tokenIsValidIsReady &&
      tokenIsValid &&
      currentUser &&
      !isUnauthenticatedRoute(location.pathname)
    ) {
      if (!currentUser.onboarding?.basicInfoIsComplete) {
        navigate('/onboarding', {
          animate: false,
        });
      } else if (!currentUser.onboarding?.interestsAreComplete) {
        navigate('/onboarding/interests', {
          animate: false,
        });
      }
    }
  }, [tokenIsValidIsReady, tokenIsValid, currentUser]);

  return (
    <>
      <Views>
        <LocationPermissionGate />
        <View main url={isNativePlatform() ? '/' : undefined}>
          <AppNavigation />
        </View>
      </Views>
    </>
  );
};
