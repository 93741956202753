import { useQuery } from '@tanstack/react-query';

import { useAppContext } from '@contexts/AppContext';

import API from '@api/API';

import { useCurrentUserCity, useCurrentUserLocation } from '@hooks/useCurrentUserLocation';

import { CritiqueData, UserCritique } from './CritiqueQueries';

const eventRsvpOptions = ['yes', 'no', 'maybe'] as const;
export type EventRsvpOption = (typeof eventRsvpOptions)[number];

const eventInterestOptions = ['interested', 'notInterested'] as const;
export type EventInterestOption = (typeof eventInterestOptions)[number];

interface PublicPromoter {
  _id: string;
  title: string;
}

interface PublicVenue {
  _id: string;
  title: string;
}

export interface FeedEvent {
  _id: string;
  img?: string;
  title: string;
  timeDetails: string;
  description: string;
  excerpt?: string;
  promoters?: PublicPromoter[];
  start: string;
  startDate: string;
  venue: PublicVenue;
  externalUrl?: string;
  isHappeningNow?: boolean;
}

export const useEvents = (
  queryParams: { cityId?: string; startDate?: string; endDate?: string; includeInactive?: 'true' },
  isEnabled = true,
) => {
  const { cityId, startDate, endDate, includeInactive } = queryParams;
  const queryString = new URLSearchParams(queryParams).toString();

  const { isFetched, data, refetch } = useQuery({
    queryKey: ['events', cityId, startDate, endDate, includeInactive],
    queryFn: () => API.get<{ events: any[] }>(`/v1/events?${queryString}`),
    enabled: isEnabled,
  });

  return {
    eventsAreReady: isFetched,
    events: data?.events,
    refetchEvents: refetch,
  };
};

export const useUserEventsQuery = (options: { refetchOnWindowFocus?: boolean | 'always' } = {}) => {
  const { currentLocation } = useCurrentUserLocation();
  const { currentCity } = useCurrentUserCity();
  const { lat, lng } = currentLocation ?? {};
  const { refetchOnWindowFocus } = options;

  const {
    isFetched: eventsAreReady,
    data = { events: [], suggested: [], saved: [], going: [] },
    refetch,
  } = useQuery({
    queryKey: ['events', currentCity?._id],
    queryFn: () =>
      API.get<{
        events: FeedEvent[];
        suggested: FeedEvent[];
        going: FeedEvent[];
        saved: FeedEvent[];
      }>(`/v1/user/events?lat=${lat}&lng=${lng}&page=0&limit=50`),
    enabled: !!lat && !!lng,
    refetchOnWindowFocus,
  });

  return {
    eventsAreReady,
    events: data,
    refetchEvents: refetch,
  };
};

export const useEventQuery = (eventId: string) => {
  const {
    isFetched: eventIsReady,
    data,
    refetch,
  } = useQuery({
    queryKey: ['event', eventId],
    queryFn: () => API.get<{ event: any }>(`/v1/events/${eventId}`),
    enabled: !!eventId,
  });

  return {
    eventIsReady,
    event: data?.event,
    refetchEvent: refetch,
  };
};

export interface UserEventRsvp {
  isStarred?: boolean;
  isLiked?: boolean;
  rsvp?: EventRsvpOption;
  eventInterest?: EventInterestOption;
  interested?: string[];
  notInterested?: string[];
}

interface UserEventsRsvps {
  rsvps: Record<string, UserEventRsvp>;
}

export const useCurrentUserEventRsvpsQuery = (isEnabled = true) => {
  const { userIsLoggedIn } = useAppContext();

  const { isFetched, data, refetch } = useQuery({
    queryKey: useCurrentUserEventRsvpsQuery.queryKey(),
    queryFn: () => useCurrentUserEventRsvpsQuery.queryFn(),
    enabled: isEnabled && userIsLoggedIn,
    refetchOnMount: 'always',
    refetchOnReconnect: 'always',
    refetchOnWindowFocus: 'always',
  });

  return {
    eventRsvpsAreReady: isFetched,
    eventRsvps: data?.rsvps,
    refetchEventRsvps: refetch,
  };
};
useCurrentUserEventRsvpsQuery.queryKey = () => ['event-rsvps'];
useCurrentUserEventRsvpsQuery.queryFn = () => API.get<UserEventsRsvps>(`/v1/user/events/rsvps`);

export const useCurrentUserEventRsvp = (eventId: string) => {
  const { eventRsvpsAreReady, eventRsvps } = useCurrentUserEventRsvpsQuery();

  return { rsvp: eventRsvps?.[eventId] };
};

export interface InterestData {
  eventInterest: Record<EventInterestOption, number>;
  reasons: Record<EventInterestOption, { count: number; label: string }[]>;
  reasonTotals: Record<EventInterestOption, number>;
}

export const useEventInterestDataQuery = (eventId: string, isEnabled = true) => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: useEventInterestDataQuery.queryKey(eventId),
    queryFn: () => useEventInterestDataQuery.queryFn(eventId),
    enabled: isEnabled && !!eventId,
    refetchOnMount: 'always',
    refetchOnReconnect: 'always',
    refetchOnWindowFocus: 'always',
  });

  return {
    eventInterestDataIsReady: isFetched,
    eventInterestData: data?.interestData,
    refetchEventInterestData: refetch,
  };
};
useEventInterestDataQuery.queryKey = (eventId) => ['event-interest', eventId];
useEventInterestDataQuery.queryFn = (eventId) =>
  API.get<{ interestData: InterestData }>(`/v1/events/${eventId}/interest`);

export const useEventCritiqueDataQuery = (eventId: string, isEnabled = true) => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: useEventCritiqueDataQuery.queryKey(eventId),
    queryFn: () => useEventCritiqueDataQuery.queryFn(eventId),
    enabled: isEnabled && !!eventId,
    refetchOnMount: 'always',
    refetchOnReconnect: 'always',
    refetchOnWindowFocus: 'always',
  });

  return {
    eventCritiqueDataIdReady: isFetched,
    eventCritiqueData: data?.critiqueData,
    refetchEventCritiqueData: refetch,
  };
};
useEventCritiqueDataQuery.queryKey = (eventId) => ['event-critiques', eventId];
useEventCritiqueDataQuery.queryFn = (eventId) =>
  API.get<{ critiqueData: CritiqueData }>(`/v1/events/${eventId}/critiques`);

export const useCurrentUserEventCritiqueQuery = (eventId: string, isEnabled = true) => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: useCurrentUserEventCritiqueQuery.queryKey(eventId),
    queryFn: () => useCurrentUserEventCritiqueQuery.queryFn(eventId),
    enabled: isEnabled,
  });

  return {
    userEventCritiqueIdReady: isFetched,
    userEventCritique: data?.userCritique,
    refetchUserEventCritique: refetch,
  };
};
useCurrentUserEventCritiqueQuery.queryKey = (eventId) => ['user-critiques', 'events', eventId];
useCurrentUserEventCritiqueQuery.queryFn = (eventId) =>
  API.get<{ userCritique: UserCritique }>(`/v1/user/critiques/events/${eventId}`);
